import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import {
  PosTypeActions,
  setDialog,
  setSearchBody,
} from "../../../../../app/slices/posType";
import Permission from "../../../../../components/userPermission/userPermission";

const PosFieldTable = () => {
  const dispatch = useDispatch();

  const _PosField = useSelector((state) => state.posType.posTypes);
  const _SearchBody = useSelector((state) => state.posType.searchBody);

  const [search, setSearch] = useState({
    name: "",
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(PosTypeActions.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 2,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="pos_type_edit">
              <IconButton
                onClick={() =>
                  dispatch(PosTypeActions.getById(params.row.id, true)).then(
                    (x) => dispatch(setDialog())
                  )
                }
              >
                <BiEdit />
              </IconButton>
            </Permission>
            <Permission permission="pos_type_delete">
              <IconButton onClick={() => handleDeleteClick(params.row)}>
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(PosTypeActions.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.name,
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.name}
              onChange={(e) => {
                setSearch({
                  ...search,
                  name: e.target.value,
                });
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      name: "",
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "63vh", width: "100%" }}>
              <DataGrid
                rows={_PosField.data.map((x, i) => {
                  return {
                    ...x,
                    index: i + 1 + pageSize * currentPage,
                  };
                })}
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_PosField.total}
                loading={_PosField.loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PosFieldTable;

import Typography from "@mui/material/Typography";
import { Chip, IconButton, LinearProgress, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TransferBalance } from "../../../app/slices/transferBalanceSlice";
import dayjs from "dayjs";
import { BiTrash } from "react-icons/bi";
import ConfirmationDialog from "../../../helpers/ConfirmationDialog";
import Permission from "../../../components/userPermission/userPermission";

const TransferBalanceTable = ({ setPage, setCurrentPage }) => {
  const dispatch = useDispatch();

  const _TransferBalance = useSelector(
    (state) => state.transferBalance.transferBalances
  );
  const loading = useSelector(
    (state) => state.transferBalance.transferBalance.loading
  );
  const _SearchBody = useSelector((state) => state.transferBalance.searchBody);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(TransferBalance.refunded({ order_id: dialogData.id }));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "id",
      headerName: "رقم الطلب",
      flex: 0.5,
    },
    {
      field: "sim_card_name",
      headerName: "اسم السيم كارت",
      flex: 1,
    },
    {
      field: "pos_name",
      headerName: "اسم نقطة البيع",
      flex: 1,
    },
    {
      field: "item",
      headerName: "اسم المنتج",
      flex: 1,
    },
    {
      field: "total_amount",
      headerName: "المبلغ الكلي (IQD)",
      flex: 1,
      renderCell: (params) => params.row.total_amount.toLocaleString(),
    },
    {
      field: "mobile",
      headerName: "رقم الهاتف ",
      flex: 1,
    },
    {
      field: "Status",
      headerName: "الحالة",
      flex: 1.2,
      renderCell: (params) => (
        <Stack direction="row" spacing={1}>
          <Chip
            label={params.row.is_success ? "نجحت العملية" : "فشلت العملية"}
            color={params.row.is_success ? "default" : "error"}
          ></Chip>
          {params.row.is_refunded ? (
            <Chip
              label={params.row.is_refunded ? " استرجاع" : "فشل الاسترجاع"}
            ></Chip>
          ) : null}
        </Stack>
      ),
    },
    {
      field: "createdAt",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD "),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Permission permission="order_transfer_delete">
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <BiTrash />
            </IconButton>
          </Permission>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(TransferBalance.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          المجموع الكلي : {_TransferBalance.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  return (
    <Box sx={{ height: "72vh", width: "100%" }}>
      <ConfirmationDialog
        open={openConfirm}
        title="تاكيد"
        message="هل انت متاكد من حذف الطلب المرقم"
        name={dialogData.id}
        confirmText="حذف"
        cancelText="الغاء"
        onConfirm={() => handleConfirmDelete()}
        onCancel={handleCancelDelete}
      />
      <DataGrid
        rows={
          _TransferBalance.data?.map((x, index) => ({
            ...x,
            index: index + 1,
          })) ?? []
        }
        columns={columns}
        pageSize={_SearchBody.take}
        page={_SearchBody.currentPage}
        rowCount={_TransferBalance.total}
        loading={loading}
        rowHeight={40}
        pagination
        paginationMode="server"
        selectionModel={[]}
        onPageChange={(newPage) => {
          setCurrentPage(newPage);
        }}
        rowsPerPageOptions={[15, 25, 50, 100]}
        onPageSizeChange={(newPageSize) => {
          setPage(newPageSize);
        }}
        components={{
          LoadingOverlay: LinearProgress,
          Footer: CustomFooter,
        }}
      />
    </Box>
  );
};

export default TransferBalanceTable;

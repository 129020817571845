import { forwardRef, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  InputAdornment,
  LinearProgress,
  Divider,
  Tooltip,
  IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import {
  setDialog,
  resetForm,
  PointOfSales,
} from "../../../app/slices/posSlice";
import { Controller, useForm } from "react-hook-form";
import { SelectGroup } from "../../../components/Selectors/SelectGroup";
import { SelectProvince } from "../../../components/Selectors/SelectProvince";
import { useState } from "react";
import { SelectRepresentative } from "../../../components/Selectors/SelectRepresentative";
import { NumericFormat } from "react-number-format";
import { SelectCity } from "../../../components/Selectors/SelectCity";
import {
  setCityTable,
  setSelectedProvince,
} from "../../../app/slices/provincesSlice";
import { IoLocation } from "react-icons/io5";
import { SelectPosType } from "../../../components/Selectors/SelectPosType";
import { SelectPosField } from "../../../components/Selectors/SelectPosField";
import { SelectJGroup } from "../../../components/Selectors/jomla/SelectJGroup";
import { SelectJRep } from "../../../components/Selectors/SelectJRep";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PosForm = (props) => {
  const _PointOfsales = useSelector((state) => state.pointOfsales.pos);
  const _SearchBody = useSelector((state) => state.pointOfsales.searchBody);
  const _Province = useSelector((state) => state.provinces.provinces);
  const _Group = useSelector((state) => state.group.groupsList);
  const _User = useSelector((state) => state.user.userInfo);
  const _PosType = useSelector((state) => state.posType.posTypes.data);
  const _PosField = useSelector((state) => state.posField.posFields.data);
  const _JGroup = useSelector((state) => state.jGroup.jGroupList);
  const _JRep = useSelector((state) => state.user.jRepList.data);

  const [canTransfer, setCanTransfer] = useState(
    _PointOfsales.form.can_transfer || false
  );
  const [hasCardRecharge, setHasCardRecharge] = useState(
    _PointOfsales.form.has_card_recharge || false
  );
  const isAdd = _PointOfsales.form.id ? false : true;
  const {
    formState: { errors },
    control,
    watch,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: _PointOfsales.form,
  });

  const isRepresentative = _User.Roles[0].name === "representative";

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });

  const onSubmit = (data) => {
    if (isAdd) {
      dispatch(
        PointOfSales.create(
          {
            user_name: data.user_name,
            password: data.password,
            email: data.email,
            mobile: data.mobile,
            name: data.name,
            address: data.address,
            is_active: data.is_active,
            owner: data.owner,
            group_id: data.group_id.id,
            can_transfer: data.can_transfer,
            province_id: data.province_id.id,
            wage: canTransfer ? data.wage : 1,
            max_daily_transfer: canTransfer ? data.max_daily_transfer : 0,
            max_monthly_transfer: canTransfer ? data.max_monthly_transfer : 0,
            max_debt: data.max_debt,
            max_balance: data.max_balance,
            city_id: data.city_id.id,
            rep_id: data.rep_id?.id ?? null,
            j_rep_id: data.j_rep_id ?? null,
            daily_transfer_balance: data.daily_transfer_balance,
            is_approved: data.is_approved ? 1 : 0,
            latitude: data.latitude,
            longitude: data.longitude,
            has_card_issuing: data.has_card_issuing,
            has_external_orders: data.has_external_orders,
            sim_card_number: data.sim_card_number,
            pos_type_id: data.pos_type_id,
            pos_field_id: data.pos_field_id,
            j_group_id: data.j_group_id,
            other_id: data.other_id ?? "",
          },
          _SearchBody,
          true,
          props.inNeedReload
        )
      );
    } else {
      dispatch(
        PointOfSales.update(
          data.id,
          {
            name: data.name,
            owner: data.owner,
            mobile: data.mobile,
            group_id: data.group_id.id,
            can_transfer: data.can_transfer,
            province_id: data.province_id.id,
            address: data.address,
            wage: data.wage,
            max_daily_transfer: data.max_daily_transfer,
            max_monthly_transfer: data.max_monthly_transfer,
            user_name: data.user_name,
            max_balance: data.max_balance,
            max_debt: data.max_debt,
            rep_id: data.rep_id?.id ?? null,
            j_rep_id: data.j_rep_id ?? null,
            city_id: data.city_id.id,
            daily_transfer_balance: data.daily_transfer_balance,
            is_approved: data.is_approved ? 1 : 0,
            latitude: data.latitude,
            longitude: data.longitude,
            has_card_recharge: data.has_card_recharge,
            has_card_issuing: data.has_card_issuing,
            has_external_orders: data.has_external_orders,
            sim_card_number: data.sim_card_number,
            pos_type_id: data.pos_type_id,
            pos_field_id: data.pos_field_id,
            j_group_id: data.j_group_id,
            other_id: data.other_id ?? "",
          },
          _SearchBody,
          props.inNeedReload,
          props.from,
          props.jOrderSearchBody,
          props.jOrderId
        )
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetForm());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canTransfer === false) {
      setValue("max_monthly_transfer", 0);
      setValue("max_daily_transfer", 0);
      setValue("wage", 0);
    } else {
      setValue("max_monthly_transfer", 5000000);
      setValue("max_daily_transfer", 1000000);
      setValue("wage", 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canTransfer]);

  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(setDialog());
  };

  useEffect(() => {
    const selectedCities = _Province.data?.filter((x) =>
      x.id === watch("province_id")?.id ? x.city : null
    )[0];
    dispatch(setCityTable(selectedCities?.city));
    dispatch(
      setSelectedProvince({
        id: watch("province_id") ? watch("province_id").id : null,
        name: watch("province_id") ? watch("province_id").name : "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_Province, watch("province_id")]);

  useEffect(() => {
    if (watch("group_id") == null) setValue("group_id", _Group?.data[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_Province]);

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      dir="rtl"
      onClose={() => {
        handleDialog();
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {isAdd ? "أضافة " : "تعديل "}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          {_PointOfsales.loading && <LinearProgress sx={{ marginBottom: 1 }} />}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {isAdd && (
                <Controller
                  name="user_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="اسم المستخدم"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="الاسم"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="other_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="معرف النقطة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            {isAdd ? (
              <Grid item xs={12} md={12}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="كلمة المرور"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            ) : null}
            {isAdd ? (
              <Grid item xs={12} md={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      label="البريد الالكتروني"
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Grid>
            ) : null}
            <Grid item xs={12} md={12}>
              <Controller
                name="owner"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المالك"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="mobile"
                rules={{
                  required: { value: true, message: "الحقل مطلوب" },
                  pattern: {
                    value: /^(?:\+964|00964|07[5798])[0-9]{8}$/,
                    message: "رقم الهاتف غير صحيح",
                  },
                }}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    size="small"
                    label="رقم الهاتف"
                    fullWidth
                    error={!!errors.mobile}
                    helperText={errors.mobile ? errors.mobile.message : ""}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="sim_card_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="رقم الشريحة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            {!isRepresentative && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="rep_id"
                  control={control}
                  render={({ field }) => (
                    <SelectRepresentative
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("rep_id", newValue ? newValue : null);
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {!isRepresentative && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="j_rep_id"
                  control={control}
                  render={({ field }) => (
                    <SelectJRep
                      onChange={(e, newValue) => {
                        setValue("j_rep_id", newValue ? newValue.id : null);
                      }}
                      value={
                        _JRep.filter((x) => x.id === field.value)[0] ?? null
                      }
                    />
                  )}
                />
              </Grid>
            )}
            {!isRepresentative && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="group_id"
                  control={control}
                  render={({ field }) => (
                    <SelectGroup
                      required
                      value={field.value}
                      onChange={(e, newValue) => {
                        setValue("group_id", newValue);
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {!isRepresentative && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="j_group_id"
                  control={control}
                  render={({ field }) => (
                    <SelectJGroup
                      required
                      onChange={(e, newValue) => {
                        setValue("j_group_id", newValue ? newValue?.id : null);
                      }}
                      value={
                        _JGroup.filter((x) => x.id === field.value)[0] ?? null
                      }
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Controller
                name="province_id"
                control={control}
                render={({ field }) => (
                  <SelectProvince
                    required
                    value={field.value}
                    onChange={(e, newValue) => {
                      setValue("province_id", newValue ? newValue : null);
                      setValue("city_id", null);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="city_id"
                control={control}
                render={({ field }) => (
                  <SelectCity
                    {...field}
                    required
                    value={field.value}
                    onChange={(e, newValue) => {
                      setValue("city_id", newValue ? newValue : null);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="اقرب نقطة دالة"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="pos_type_id"
                control={control}
                render={({ field }) => (
                  <SelectPosType
                    required
                    onChange={(e, newValue) => {
                      setValue("pos_type_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _PosType.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="pos_field_id"
                control={control}
                render={({ field }) => (
                  <SelectPosField
                    required
                    onChange={(e, newValue) => {
                      setValue("pos_field_id", newValue ? newValue.id : null);
                    }}
                    value={
                      _PosField.filter((x) => x.id === field.value)[0] ?? null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="daily_transfer_balance"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="تحويل الرصيد في اليوم"
                    fullWidth
                    {...field}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {!isRepresentative && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="max_balance"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="الحد الاعلى للرصيد"
                      fullWidth
                      {...field}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                        startAdornment: (
                          <InputAdornment position="start">IQD</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {!isRepresentative && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="max_debt"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="الحد الاعلى للدين"
                      fullWidth
                      {...field}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                        startAdornment: (
                          <InputAdornment position="start">IQD</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Controller
                name="has_card_issuing"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("has_card_issuing", e.target.checked);
                        }}
                      />
                    }
                    label="اصدار البطاقات"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="has_external_orders"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("has_external_orders", e.target.checked);
                        }}
                      />
                    }
                    label="الفواتير الخارجية"
                  />
                )}
              />
            </Grid>
            {!isAdd && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="has_card_recharge"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            setHasCardRecharge(!hasCardRecharge);
                            setValue("has_card_recharge", e.target.checked);
                          }}
                        />
                      }
                      label="شحن البطاقات"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6} md={6}>
              <Controller
                name="is_approved"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          setValue("is_approved", e.target.checked);
                        }}
                      />
                    }
                    label="المصادقه"
                  />
                )}
              />
            </Grid>
            {!isRepresentative && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="can_transfer"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            setCanTransfer(!canTransfer);
                            setValue("can_transfer", e.target.checked);
                          }}
                        />
                      }
                      label="امكانية التحويل"
                    />
                  )}
                />
              </Grid>
            )}
            {canTransfer && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="max_daily_transfer"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="الحد الاعلى للتحويل في اليوم"
                      fullWidth
                      value={field.value}
                      onChange={(e) =>
                        setValue("max_daily_transfer", e.target.value)
                      }
                      {...field}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                        startAdornment: (
                          <InputAdornment position="start">IQD</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {canTransfer && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="max_monthly_transfer"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="الحد الاعلى للتحويل في الشهر"
                      fullWidth
                      value={field.value}
                      onChange={(e) =>
                        setValue("max_monthly_transfer", e.target.value)
                      }
                      {...field}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                        startAdornment: (
                          <InputAdornment position="start">IQD</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {canTransfer && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="wage"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      size="small"
                      label="العمولة"
                      fullWidth
                      {...field}
                      InputProps={{
                        inputComponent: NumericFormatCustom,
                      }}
                      helperText="1 = 0.001"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={5}>
              <Controller
                name="latitude"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="خط العرض (Latitude)"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <Controller
                name="longitude"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="خط الطول (Longitude)"
                    fullWidth
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Tooltip title="الموقع">
                <IconButton
                  disabled={
                    watch("longitude") === null || watch("latitude") === null
                  }
                  onClick={() => {
                    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${watch(
                      "latitude"
                    )},${watch("longitude")}`;
                    window.open(googleMapsUrl, "_blank");
                  }}
                >
                  <IoLocation />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button type="submit" variant="contained" color="primary">
                حفظ
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default PosForm;

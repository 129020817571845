import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Divider,
  Tooltip,
  Autocomplete,
  Chip,
  useMediaQuery,
  TablePagination,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridPagination,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { User } from "../../../../../../app/slices/userSlice";
import {
  Wallet,
  setRecivedCashDialog,
  setTransferDialog,
  setTopupDialog,
  setWithdrawDialog,
  setClearanceDialog,
  setWithdrawPosDialog,
  setCorrectBalanceDialog,
  setTransactionsSearchBody,
} from "../../../../../../app/slices/walletSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { MdOutlineClear } from "react-icons/md";
import TransactionForm from "../../form";
import DateRangepicker from "../../../../../../helpers/dateRangePicker";
import { SelectTransactionTypes } from "../../../../../../components/Selectors/SelectTransactionTypes";
import { BiCalculator, BiInfoCircle, BiRefresh } from "react-icons/bi";
import { PointOfSales } from "../../../../../../app/slices/posSlice";
import { ExportButton } from "../../../../../../components/ExportExcel/ExportButton";
import ConfirmationDialog from "../../../../../../helpers/ConfirmationDialog";
import { useTheme } from "@emotion/react";
import Permission from "../../../../../../components/userPermission/userPermission";

const TransactionTable = () => {
  const { user_id } = useParams();

  const dispatch = useDispatch();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [difference, setDifference] = useState(null);
  const [search, setSearch] = useState({
    search: "",
    order_id: "",
    user_type: null,
    date: "",
    type: null,
    startDate: dayjs().format("2023-01-01"),
    endDate: dayjs().format("YYYY-MM-DD"),
    skip: 0,
    transaction_number: "",
  });
  const _Loading = useSelector((state) => state.wallet.wallet.loading);
  const transactionsSearchBody = useSelector(
    (state) => state.wallet.transactionsSearchBody
  );
  const _LoadingMonthlyRep = useSelector(
    (state) => state.wallet.monthlyRep.loading
  );
  const _Transactions = useSelector((state) => state.wallet.transactions);
  const user = useSelector((state) => state.user.user.form);
  const _Wallet = useSelector((state) => state.wallet);
  const userInfo = useSelector((state) => state.user.userInfo);
  const _TransactionTypes = useSelector(
    (state) => state.wallet.transactionType
  );
  const _MonthlyRep = useSelector((state) => state.wallet.monthlyRep);

  const [selectedMonth, setSelectedMonth] = useState(dayjs().format("MM"));
  const [reload, setReload] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [dialogActiveData, setDialogActiveData] = useState({});
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const checkBalanceDifferenceDate = (transaction_id) => {
    dispatch(
      Wallet.checkBalanceDifferenceDate({
        user_id: user.id,
        transaction_id: transaction_id,
      })
    ).then((res) => {
      setDifference(res.data.diff);
    });
  };

  const columns = [
    {
      field: "number",
      headerName: "رقم المناقلة",
      flex: 1,
    },
    {
      field: "order_id",
      headerName: "رقم الطلب",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "القيمة (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{params.row.amount?.toLocaleString()}</Typography>;
      },
    },
    {
      field: "by_user_name",
      headerName: "بواسطة",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{params.row.by_user_name}</Typography>;
      },
    },
    {
      field: "from_user_name",
      headerName: "من المحفظة",
      flex: 1,
    },
    {
      field: "to_user_name",
      headerName: " الى المحفظة",
      flex: 1,
    },
    {
      field: "from_user",
      headerName: "حساب المرسل (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography>
              الرصيد: {params.row.from_current_balance?.toLocaleString()}
            </Typography>
            <Typography>
              الديون :{params.row.from_current_debt?.toLocaleString()}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "to_user",
      headerName: "حساب المستلم (IQD)",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack
            direction="column"
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography>
              الرصيد: {params.row.to_current_balance?.toLocaleString()}
            </Typography>
            <Typography>
              الديون :{params.row.to_current_debt?.toLocaleString()}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "created_at",
      headerName: "تاريخ العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params.row.created_at).format("HH:mm:ss / YYYY-MM-DD ")}
          </Typography>
        );
      },
    },
    {
      field: "type",
      headerName: "نوع العملية",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Stack direction={"row"} spacing={1}>
              <Typography>{params.row.type}</Typography>
              {params.row.note && (
                <Tooltip title={params.row.note}>
                  <IconButton size="small">
                    <BiInfoCircle />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            <Stack direction={"row"}>
              <IconButton
                size="small"
                onClick={() => checkBalanceDifferenceDate(params.row.id)}
              >
                <BiCalculator />
              </IconButton>
              {/* <Typography>{difference}</Typography> */}
            </Stack>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Wallet.getAllTransaction(transactionsSearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, transactionsSearchBody]);

  useEffect(() => {
    dispatch(
      setTransactionsSearchBody({
        user_id: user_id,
        order_id: search.order_id,
        date: search.date,
        skip: currentPage * pageSize,
        take: pageSize,
        type: search.type ? JSON.stringify(search.type) : "",
        start_date: search.startDate,
        end_date: search.endDate,
        transaction_number: search.transaction_number,
        search: search.search,
      })
    );
  }, [currentPage, dispatch, pageSize, search, user_id]);

  useEffect(() => {
    dispatch(User.getByIdData(user_id));
  }, [user_id, _Wallet.reload, dispatch, reload]);

  function monthNumberToDate(monthNumber) {
    const currentYear = dayjs().year();
    const date = dayjs(`${currentYear}-${monthNumber}-01`);
    const formattedDate = date.format("YYYY-MM-DD");

    return formattedDate;
  }

  useEffect(() => {
    if (user.is_representative)
      dispatch(
        Wallet.getMonthlyRep({
          rep_id: user_id,
          date: monthNumberToDate(selectedMonth),
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id, _Wallet.reload, selectedMonth]);

  const getUserType = (_user) => {
    if (_user.is_representative) return "مندوب";
    else if (_user.is_j_rep) return "مندوب جملة";
    else if (_user.role === "pos")
      return `نقطة بيع : ${_user.pos_number ? `(${_user.pos_number})` : ""}`;
    else return "غير معرف";
  };

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_Transactions.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const customToolbar = () => (
    <GridToolbarContainer>
      <ExportButton fileName="تقرير كشف الحساب | نظام دجلة" />

      {/* <GridToolbarExport
        csvOptions={{
          fileName: "تقرير كشف الحساب | نظام دجلة",
          utf8WithBom: true,
        }}
      /> */}
    </GridToolbarContainer>
  );

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    dispatch(
      User.WalletActivation({
        user_id: dialogActiveData.id,
        is_active: !dialogActiveData.wallet?.is_active,
      })
    )
      .then((x) => {
        setReload(!reload);
      })
      .catch((e) => {
        setReload(!reload);
      });
    setOpenActive(false);
  };

  const handleCancelActive = () => {
    setOpenActive(false);
  };

  return (
    <Card>
      <CardContent>
        <Box>
          <TransactionForm />
          <ConfirmationDialog
            open={openActive}
            title="تاكيد"
            message={
              dialogActiveData.wallet?.is_active
                ? "هل انت متاكد من الغاء تفعيل المحفظة: "
                : "هل انت متاكد من تفعيل المحفظة :"
            }
            name={dialogActiveData.user_name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} sx={{ mx: 1 }}>
              <Grid item xs={12} md={2}>
                <Stack spacing={2}>
                  <Typography variant="h4">
                    الاسم : {user ? user.name : ""}
                  </Typography>
                  <Typography variant="h4">
                    رقم الهاتف : {user ? user.mobile : ""}
                  </Typography>
                  <Typography variant="h4">
                    {" "}
                    نوع الحساب : {user ? getUserType(user) : ""}
                  </Typography>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography variant="h4"> حالة المحفظة : </Typography>
                    <Chip
                      label={user.wallet?.is_active ? "فعال" : "غير فعال"}
                      color={user.wallet?.is_active ? "success" : "error"}
                      onClick={() => {
                        handleActiveClick(user);
                      }}
                    ></Chip>
                  </Stack>
                  <Permission permission="pos_edit">
                    <Button
                      sx={{ width: 200 }}
                      variant="contained"
                      fullWidth
                      onClick={() =>
                        dispatch(PointOfSales.getById(user.pos_id))
                      }
                    >
                      تعديل
                    </Button>
                  </Permission>
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack spacing={2}>
                  <Typography variant="h4">
                    الرصيد : {user.wallet?.balance?.toLocaleString()} IQD
                  </Typography>
                  <Typography variant="h4" color={"error"}>
                    الديون : {user.wallet?.debt?.toLocaleString()} IQD
                  </Typography>
                  {!user.is_representative && (
                    <Stack direction={"row"}>
                      <Typography variant="h4">
                        فرق الرصيد :{" "}
                        {user.wallet?.balance_difference?.toLocaleString()} IQD
                      </Typography>
                      <IconButton
                        size="large"
                        onClick={() =>
                          dispatch(Wallet.checkBalanceDifference(user.id))
                        }
                      >
                        <BiRefresh />
                      </IconButton>
                    </Stack>
                  )}
                  {user.is_representative && (
                    <Typography variant="h4" color={"success"}>
                      الكاش : {`${user.wallet?.cash.toLocaleString()} IQD`}
                    </Typography>
                  )}
                  {user.is_representative && (
                    <Stack direction="row" spacing={1} alignItems={"center"}>
                      <Typography variant="h4" color={"success"}>
                        مبيعات المندوب لشهر
                      </Typography>
                      <Autocomplete
                        size="small"
                        options={months}
                        getOptionLabel={(option) => option}
                        value={
                          months.filter((x) => x == selectedMonth)[0] ??
                          dayjs().format("MM")
                        }
                        onChange={(e, newValue) => {
                          setSelectedMonth(
                            newValue ? newValue : dayjs().format("MM")
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option == value
                        }
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                      <Typography variant="h4" color={"success"}>
                        {_MonthlyRep.data.total_income
                          ? `: ${_MonthlyRep.data.total_income.toLocaleString()} IQD`
                          : ""}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={5}>
                <Permission permission="wallet_edit">
                  <Button
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() =>
                      !user.is_representative
                        ? dispatch(setRecivedCashDialog())
                        : dispatch(setClearanceDialog())
                    }
                  >
                    تسديد ديون
                  </Button>
                </Permission>
                <Permission permission="wallet_edit">
                  <Button
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => dispatch(setTransferDialog())}
                  >
                    مناقلة رصيد
                  </Button>
                </Permission>
                <Permission permission="wallet_edit">
                  <Button
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => dispatch(setWithdrawPosDialog())}
                  >
                    سحب رصيد من نقطة البيع
                  </Button>
                </Permission>
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => dispatch(setCorrectBalanceDialog())}
                >
                  تعديل العملية
                </Button>
                <Permission permission="wallet_edit">
                  <Button
                    variant="contained"
                    onClick={() => dispatch(setWithdrawDialog())}
                  >
                    سحب رصيد من المندوب
                  </Button>
                </Permission>
                <Permission permission="wallet_edit">
                  <Button
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => dispatch(setTopupDialog())}
                  >
                    اضافة رصيد للمندوب
                  </Button>
                </Permission>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {_LoadingMonthlyRep && <LinearProgress />}
            <Divider sx={{ my: 2 }} />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="رقم نقطة، اسم نقطة بيع او رقم هاتف"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.transaction_number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  transaction_number: e.target.value,
                });
              }}
              label="بحث عن رقم المناقلة"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.order_id}
              onChange={(e) => {
                setSearch({
                  ...search,
                  order_id: e.target.value,
                });
              }}
              label="بحث عن رقم الطلب"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectTransactionTypes
              multiple
              onChange={(event, value) => {
                var ids = value.map((x) => x.key);
                setSearch({
                  ...search,
                  type: value ? ids : null,
                });
              }}
              value={
                _TransactionTypes?.filter((x) =>
                  search.type?.includes(x.key)
                ) ?? []
              }
            // value={search.type}
            // onChange={(e, newValue) => {
            //   setSearch({
            //     ...search,
            //     type: newValue ? newValue : null
            //   })
            // }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      search: "",
                      order_id: "",
                      user_type: null,
                      date: "",
                      type: null,
                      startDate: dayjs().format("2023-01-01"),
                      endDate: dayjs().format("YYYY-MM-DD"),
                      skip: 0,
                      take: 15,
                      transaction_number: "",
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          {smallScreen ? (
            _Transactions?.data?.length > 0 ? (
              <Grid item xs={12}>
                {_Loading ? <LinearProgress /> : null}
                {_Transactions.data.map((row, index) => (
                  <Card key={index} sx={{ marginBottom: 0.5 }}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography>
                            رقم المناقلة: <strong>{row.number}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            رقم الطلب: <strong>{row.order_id}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            القيمة :{" "}
                            <strong>{row.amount?.toLocaleString()}</strong> IQD
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            من المحفظة: <strong>{row.from_user_name}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            الى المحفظة: <strong>{row.to_user_name}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color={"error"}>
                            رصيد المرسل:{" "}
                            <strong>
                              {row.from_current_balance?.toLocaleString()}
                            </strong>{" "}
                            IQD
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color={"error"}>
                            ديون المرسل:{" "}
                            <strong>
                              {row.from_current_debt?.toLocaleString()}
                            </strong>{" "}
                            IQD
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color={"green"}>
                            رصيد المستلم:{" "}
                            <strong>
                              {row.to_current_balance?.toLocaleString()}
                            </strong>{" "}
                            IQD
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color={"green"}>
                            ديون المستلم:{" "}
                            <strong>
                              {row.to_current_debt?.toLocaleString()}
                            </strong>{" "}
                            IQD
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            تاريخ العملية:{" "}
                            <strong>
                              {dayjs(row.created_at).format(
                                "(hh:mm a) - YYYY-MM-DD"
                              )}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            نوع العملية: <strong>{row.type}</strong>
                          </Typography>
                        </Grid>
                        {row.note && (
                          <Grid item xs={12}>
                            <Typography>
                              الملاحظات: <strong>{row.note}</strong>
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Stack>
                            <Stack direction="row">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  checkBalanceDifferenceDate(row.id)
                                }
                              >
                                <BiCalculator />
                              </IconButton>
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Card sx={{ mt: 2, width: "100%" }}>
                  <TablePagination
                    component="div"
                    count={_Transactions.total}
                    rowsPerPage={pageSize}
                    page={currentPage}
                    onPageChange={(e, newPage) => setCurrentPage(newPage)}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onRowsPerPageChange={(event) => {
                      const newRowsPerPage = parseInt(event.target.value, 10);
                      setPageSize(newRowsPerPage);
                    }}
                  />
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card>
                  <Typography m={2} variant="h5" color="primary" align="center">
                    لا توجد بيانات
                  </Typography>
                </Card>
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              <Box sx={{ height: "45vh", width: "100%" }}>
                <DataGrid
                  rows={_Transactions.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Transactions.total}
                  loading={_Loading}
                  rowHeight={65}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                    Footer: CustomFooter,
                    Toolbar: customToolbar,
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TransactionTable;

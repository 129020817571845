import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Chip,
  Tooltip,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogContent,
  Slide,
  Divider,
  MenuItem,
  ListItemIcon,
  Menu,
  Stack,
  useMediaQuery,
  TablePagination,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { BsCardText, BsFillFileEarmarkExcelFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PointOfSales,
  setLastOptDialog,
  setMapDataDialog,
  setSearchBody,
} from "../../../../app/slices/posSlice";
import {
  User,
  setResetPasswordByAdminDialog,
} from "../../../../app/slices/userSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import dayjs from "dayjs";
import { SelectRepresentative } from "../../../../components/Selectors/SelectRepresentative";
import {
  MdMiscellaneousServices,
  MdOutlineClear,
  MdOutlineContentCopy,
} from "react-icons/md";
import { IoLocation, IoMap } from "react-icons/io5";
import { CiDiscount1 } from "react-icons/ci";
import { AiOutlineTransaction } from "react-icons/ai";
import { CgPassword } from "react-icons/cg";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import ResetPasswordByAdminForm from "../../../users/resetPasswordByAdmin";
import { SelectGroup } from "../../../../components/Selectors/SelectGroup";
import { PosDiscount } from "../../../../app/slices/posDiscountSlice";
import PosDiscountData from "../posDiscount";
import { showNotification } from "../../../../app/slices/notificationSlice";
import { SelectProvince } from "../../../../components/Selectors/SelectProvince";
import { HiMenu } from "react-icons/hi";
import { SelectCity } from "../../../../components/Selectors/SelectCity";
import { setCityTable } from "../../../../app/slices/provincesSlice";
import {
  PosService,
  setAddServiceDialog,
} from "../../../../app/slices/serviceSlice";
import { useTheme } from "@emotion/react";
import { SelectPosType } from "../../../../components/Selectors/SelectPosType";
import { SelectPosField } from "../../../../components/Selectors/SelectPosField";
import PosMapData from "../map";
import Permission from "../../../../components/userPermission/userPermission";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PosTabel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);
  const _PointsOfsales = useSelector((state) => state.pointOfsales.pos);
  const _UserReload = useSelector((state) => state.user.user.loading);
  const _SearchBody = useSelector((state) => state.pointOfsales.searchBody);
  const _ResetPassword = useSelector(
    (state) => state.user.resetPasswordByAdmin.dialog
  );
  const _PosDiscount = useSelector(
    (state) => state.posDiscount.posDiscount.dialog
  );
  const _OtpData = useSelector((state) => state.pointOfsales.lastOtp);
  const _provinces = useSelector((state) => state.provinces.provinces.data);
  const _Cities = useSelector((state) => state.provinces.cities.data);
  const rep = useSelector((state) => state.user.usersList.data);
  const _Group = useSelector((state) => state.group.groupsList.data);
  const _PosType = useSelector((state) => state.posType.posTypes.data);
  const _PosField = useSelector((state) => state.posField.posFields.data);
  const _PosMapDialog = useSelector(
    (state) => state.pointOfsales.mapData.dialog
  );

  useEffect(() => {
    const selectedCities = _provinces?.filter((x) =>
      x.id === _SearchBody.province_id ? x.city : null
    )[0];
    dispatch(setCityTable(selectedCities?.city));
  }, [_SearchBody.province_id, _provinces, dispatch]);

  const [search, setSearch] = useState(_SearchBody);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [reload, serReload] = useState(false);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [openServiceConfirm, setConfirmOpenService] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [dialogActiveData, setDialogActiveData] = useState({});
  const [isUserSelected, setIsUserSelected] = useState(false);

  const [activeItem, setActiveItem] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const handleMenuClick = (event, item) => {
    setOpenMenu(event.currentTarget);
    setActiveItem(item);
  };

  const handleClose = () => {
    setActiveItem(null);
    setOpenMenu(null);
  };
  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(PointOfSales.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    if (isUserSelected) {
      dispatch(
        User.userActivation({
          user_id: dialogActiveData.user_id,
          is_active: !dialogActiveData.active_user,
        })
      ).then((x) => {
        serReload(!reload);
        setIsUserSelected(false);
      });
    } else {
      dispatch(
        User.WalletActivation({
          user_id: dialogActiveData.user_id,
          is_active: !dialogActiveData.active_wallet,
        })
      ).then((x) => {
        serReload(!reload);
        setIsUserSelected(false);
      });
    }
    setOpenActive(false);
  };

  const handleCancelActive = () => {
    setOpenActive(false);
    setIsUserSelected(false);
  };

  const handlePosServiceClick = (params) => {
    setConfirmOpenService(true);
    setDialogData(params);
  };

  const handleConfirmPosService = () => {
    dispatch(
      PosService.removeServiceFromPos(dialogData.services?.id, _SearchBody)
    );
    setConfirmOpenService(false);
  };

  const handleCancelPosService = () => {
    setConfirmOpenService(false);
  };

  const handleChange = (event, newType) => {
    setSearch({
      ...search,
      is_approved: event.target.value,
    });
  };

  const operationMenu = (params) => (
    <Menu
      sx={{ direction: "ltr" }}
      anchorEl={openMenu}
      keepMountede
      id="account-menu"
      open={activeItem === params}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            left: "50%",
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Permission permission="pos_edit">
        <MenuItem onClick={() => dispatch(PointOfSales.getById(params.id))}>
          <ListItemIcon>
            <BiEdit size={25} color="#0288D1" />
          </ListItemIcon>
          تعديل
        </MenuItem>
      </Permission>
      <Permission permission="wallet_view">
        <MenuItem
          onClick={() => {
            localStorage.setItem("isWholeSale", "0");
            navigate("/app/wallets/transactions/" + params.user_id);
          }}
        >
          <ListItemIcon>
            <AiOutlineTransaction size={25} />
          </ListItemIcon>
          المناقلات
        </MenuItem>
      </Permission>
      <Permission permission="last_opt_view">
        <MenuItem
          onClick={() => dispatch(PointOfSales.getLastOtp(params.user_id))}
        >
          <ListItemIcon>
            <BsCardText size={25} />
          </ListItemIcon>
          رمز التفعيل
        </MenuItem>
      </Permission>
      <MenuItem
        disabled={params.latitude === null || params.longitude === null}
        onClick={() => {
          const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${params.latitude},${params.longitude}`;
          window.open(googleMapsUrl, "_blank");
        }}
      >
        <ListItemIcon>
          <IoLocation size={25} />
        </ListItemIcon>
        الموقع
      </MenuItem>
      <Permission permission="pos_item_discount_edit">
        <MenuItem onClick={() => dispatch(PosDiscount.getAll(params.id, true))}>
          <ListItemIcon>
            <CiDiscount1 size={25} />
          </ListItemIcon>
          الخصم
        </MenuItem>
      </Permission>
      {params.services ? (
        <Permission permission="service_edit">
          <MenuItem
            sx={{ color: "#D32F2F" }}
            onClick={() => handlePosServiceClick(params)}
          >
            <ListItemIcon>
              <MdMiscellaneousServices color="#D32F2F" size={25} />
            </ListItemIcon>
            الغاء الخدمة
          </MenuItem>
        </Permission>
      ) : (
        <Permission permission="service_edit">
          <MenuItem onClick={() => dispatch(setAddServiceDialog(params))}>
            <ListItemIcon>
              <MdMiscellaneousServices size={25} />
            </ListItemIcon>
            تفعيل خدمة
          </MenuItem>
        </Permission>
      )}
      <Permission permission="change_password_edit">
        <MenuItem
          onClick={() => dispatch(setResetPasswordByAdminDialog(params.user_id))}
        >
          <ListItemIcon>
            <CgPassword size={25} />
          </ListItemIcon>
          تغيير كلمة المرور
        </MenuItem>
      </Permission>
      <Permission permission="pos_delete">
        <MenuItem
          onClick={() => {
            handleDeleteClick(params);
          }}
        >
          <ListItemIcon>
            <BiTrash size={25} color="#D32F2F" />
          </ListItemIcon>
          حذف
        </MenuItem>
      </Permission>
    </Menu>
  );

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "other_id",
      headerName: "معرف النقطة",
      flex: 0.7,
    },
    {
      field: "name",
      headerName: "اسم النقطة",
      flex: 1,
    },
    {
      field: "pos_number",
      headerName: "رقم النقطة",
      flex: 0.6,
    },
    {
      field: "sim_card_number",
      headerName: "رقم الشريحة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row?.sim_card_number} followCursor>
            <Typography noWrap>{params.row?.sim_card_number}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "user_name",
      headerName: "اسم المستخدم",
      flex: 1,
    },
    {
      field: "group_name",
      headerName: "المجموعة",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{params.row.group?.name}</Typography>;
      },
    },
    {
      field: "representative",
      headerName: "المندوب",
      flex: 1,
      renderCell: (params) => {
        return <Typography>{params.row.representative?.name}</Typography>;
      },
    },
    {
      field: "province_name",
      headerName: "المحافظة",
      flex: 1,
    },
    {
      field: "app_version",
      headerName: "رقم اصدار التطبيق",
      flex: 1,
    },
    {
      field: "active_wallet",
      headerName: "حالة المحفظة",
      flex: 0.8,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.active_wallet ? "فعال" : "غير فعال"}
            color={params.row.active_wallet ? "success" : "error"}
            onClick={() => handleActiveClick(params.row)}
          ></Chip>
        );
      },
    },
    {
      field: "created_at",
      headerName: "تاريخ الانشاء",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <Typography>
            {dayjs(params.row.created_at).format("YYYY-MM-DD / hh:mm a")}
          </Typography>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 0.7,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              onClick={(e) => handleMenuClick(e, params.row)}
              aria-controls={openMenu ? params.row.id : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
            >
              <HiMenu />
            </IconButton>
            <Permission permission="wallet_view">
              <Tooltip title="المناقلات">
                <IconButton
                  onClick={() => {
                    localStorage.setItem("isWholeSale", "0");
                    navigate("/app/wallets/transactions/" + params.row.user_id);
                  }}
                >
                  <AiOutlineTransaction />
                </IconButton>
              </Tooltip>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(PointOfSales.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch, reload]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        rep_id: search.rep_id ?? "",
        group_id: search.group_id ?? "",
        is_approved: search.is_approved,
        pos_type_id: search.pos_type_id,
        pos_field_id: search.pos_field_id,
        min_order_amount: search.min_order_amount ?? "",
        province_id: search.province_id ?? "",
        start_date: search.startDate ?? null,
        end_date: search.endDate ?? null,
        custom_discounts: search.custom_discounts ?? "",
        has_j_balance: search.has_j_balance === 1 ? search.has_j_balance : null,
        other_id: search.other_id ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        {activeItem && operationMenu(activeItem)}
        <Box>
          {_ResetPassword && <ResetPasswordByAdminForm />}
          {_PosDiscount && <PosDiscountData />}
          {_PosMapDialog && <PosMapData />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <ConfirmationDialog
            open={openServiceConfirm}
            title="تاكيد"
            message="هل انت متاكد من الغاء تفعيل الخدمة: "
            name={dialogData.name}
            confirmText="الغاء تفعيل"
            cancelText="الغاء"
            onConfirm={() => handleConfirmPosService()}
            onCancel={handleCancelPosService}
          />
          <ConfirmationDialog
            open={openActive}
            title="تاكيد"
            message={
              isUserSelected
                ? `هل انت متاكد من ${dialogActiveData.active_user
                  ? "الغاء تفعيل الحساب"
                  : "تفعيل الحساب"
                }`
                : `هل انت متاكد من ${dialogActiveData.active_wallet
                  ? "الغاء تفعيل المحفظة"
                  : "تفعيل المحفظة"
                }`
            }
            name={dialogActiveData.name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
          <Dialog
            open={_OtpData.dialog}
            TransitionComponent={Transition}
            keepMounted
            dir="rtl"
            maxWidth="sm"
            onClose={() => {
              dispatch(setLastOptDialog());
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent sx={{ width: "500px" }}>
              <Typography mb={3} variant="h4" color={"purple"}>
                اخر كود
              </Typography>
              <Divider />
              <Typography m={4} variant="h3" color="primary">
                <span style={{ color: "black" }}>الكود:</span>{" "}
                {_OtpData?.data?.code ?? "لا يوجد كود"}{" "}
                <IconButton
                  color="primary"
                  onClick={() => {
                    navigator.clipboard
                      .writeText(_OtpData?.data?.code)
                      .then(() =>
                        dispatch(
                          showNotification({
                            message: "تم نسخ الكود بنجاح",
                            type: "success",
                          })
                        )
                      );
                  }}
                >
                  <MdOutlineContentCopy />
                </IconButton>
              </Typography>
              <Typography m={4} variant="h3" color="primary">
                <span style={{ color: "black" }}> رقم الهاتف:</span>{" "}
                {_OtpData?.data?.phone ?? ""}
              </Typography>
              <Typography m={4} variant="h3" color="primary">
                <span style={{ color: "black" }}>النوع:</span>{" "}
                {_OtpData?.data?.type ?? ""}
              </Typography>
              <Typography m={4} variant="h3" color="primary">
                <span style={{ color: "black" }}>العدد:</span>{" "}
                {_OtpData?.data?.count ?? ""}
              </Typography>
              <Typography m={4} variant="h3" color="primary">
                <span style={{ color: "black" }}> اخر تحديث:</span>{" "}
                {dayjs(_OtpData?.data?.updated_at).format(
                  "YYYY-MM-DD hh:mm a"
                ) ?? ""}
              </Typography>
            </DialogContent>
          </Dialog>
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={2}>
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={search.is_approved}
              exclusive
              onChange={handleChange}
              fullWidth
            >
              <ToggleButton value={"1"}>نقاط البيع</ToggleButton>
              <ToggleButton value={"0"}>النقاط الجديدة</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="بحث عن اسم, هاتف, رقم النقطة "
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.other_id}
              onChange={(e) => {
                setSearch({
                  ...search,
                  other_id: e.target.value,
                });
              }}
              label="بحث عن معرف النقطة"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectRepresentative
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  rep_id: newValue ? newValue.id : null,
                });
              }}
              value={rep.filter((x) => x.id === search.rep_id)[0] ?? null}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectGroup
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  group_id: newValue ? newValue.id : null,
                });
              }}
              value={_Group.filter((x) => x.id === search.group_id)[0] ?? null}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              size="small"
              value={search.min_order_amount}
              onChange={(e) => {
                setSearch({
                  ...search,
                  min_order_amount: e.target.value,
                });
              }}
              label="اقل قيمة مبيعات"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectProvince
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  province_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _provinces?.filter((x) => x.id === search.province_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectCity
              onChange={(e, newValue) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    city_id: newValue ? newValue.id : null,
                  })
                );
              }}
              value={
                _Cities?.filter((x) => x.id === _SearchBody.city_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectPosType
              onChange={(e, newValue) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    pos_type_id: newValue ? newValue.id : null,
                  })
                );
              }}
              value={
                _PosType?.filter((x) => x.id === _SearchBody.pos_type_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SelectPosField
              onChange={(e, newValue) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    pos_field_id: newValue ? newValue.id : null,
                  })
                );
              }}
              value={
                _PosField?.filter(
                  (x) => x.id === _SearchBody.pos_field_id
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <DateRangepicker
              startDate={search.startDate}
              endDate={search.endDate}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    startDate: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    endDate: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Stack direction="row" spacing={1}>
              <FormControlLabel
                control={
                  <Switch
                    checked={search.custom_discounts === 1 ? true : false}
                    onChange={(e) =>
                      setSearch({
                        ...search,
                        custom_discounts: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                }
                label="الخصم المخصص !"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={search.has_j_balance === 1 ? true : false}
                    onChange={(e) =>
                      setSearch({
                        ...search,
                        has_j_balance: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                }
                label="ديون الجملة"
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={2}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    rep_id: null,
                    group_id: null,
                    startDate: null,
                    endDate: null,
                    custom_discounts: 0,
                    has_j_balance: null,
                    is_approved: "1",
                    min_order_amount: "",
                    province_id: null,
                    other_id: "",
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
            {_Role === "representative" ? null : (
              <Tooltip title="تصدير ملف اكسل">
                <IconButton
                  onClick={() => {
                    dispatch(PointOfSales.exportExcel(_SearchBody));
                  }}
                >
                  <BsFillFileEarmarkExcelFill />
                </IconButton>
              </Tooltip>
            )}
            {_Role === "representative" ? null : (
              <Tooltip title="خريطة نقاط البيع">
                <IconButton
                  onClick={() => {
                    dispatch(setMapDataDialog(_PointsOfsales.data));
                  }}
                >
                  <IoMap />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
          {smallScreen ? (
            _PointsOfsales?.data?.length > 0 ? (
              <Grid item xs={12}>
                {_PointsOfsales.loading || _UserReload ? (
                  <LinearProgress />
                ) : null}
                {_PointsOfsales.data.map((pos, index) => (
                  <Card sx={{ marginBottom: 0.5 }} key={index}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Typography variant="h4" color="primary">
                              {pos?.name}
                            </Typography>
                            <Typography variant="h5">
                              {pos?.pos_number}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            اسم المستخدم: <strong>{pos?.user_name}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            المندوب:{" "}
                            <strong>{pos?.representative?.name}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            رقم الشريحة: <strong>{pos?.sim_card_number}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            المجموعة: <strong>{pos?.group_name}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            المحافظة: <strong>{pos?.province?.name}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            رقم اصدار التطبيق:{" "}
                            <strong>{pos?.app_version}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            حالة المحفظة:{" "}
                            <Chip
                              label={pos.active_wallet ? "فعال" : "غير فعال"}
                              color={pos.active_wallet ? "success" : "error"}
                              onClick={() => handleActiveClick(pos)}
                            ></Chip>
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="caption" color="primary">
                            {dayjs(pos?.created_at).format("YYYY-MM-DD")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <>
                            {_Role === "representative" ? null : (
                              <IconButton
                                onClick={(e) => handleMenuClick(e, pos)}
                                aria-controls={openMenu ? pos.id : undefined}
                                aria-haspopup="true"
                                aria-expanded={openMenu ? "true" : undefined}
                              >
                                <HiMenu />
                              </IconButton>
                            )}

                            <IconButton
                              onClick={() => {
                                const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${pos.latitude},${pos.longitude}`;
                                window.open(googleMapsUrl, "_blank");
                              }}
                              disabled={
                                pos.latitude === null || pos.longitude === null
                              }
                              aria-controls={openMenu ? pos.id : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? "true" : undefined}
                            >
                              <IoLocation size={25} />
                            </IconButton>

                            <Tooltip title="المناقلات">
                              <IconButton
                                onClick={() => {
                                  localStorage.setItem("isWholeSale", "0");
                                  navigate(
                                    "/app/wallets/transactions/" + pos.user_id
                                  );
                                }}
                              >
                                <AiOutlineTransaction />
                              </IconButton>
                            </Tooltip>
                          </>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Card sx={{ mt: 2, width: "100%" }}>
                  <TablePagination
                    component="div"
                    count={_PointsOfsales.total}
                    rowsPerPage={pageSize}
                    page={currentPage}
                    onPageChange={(e, newPage) => setCurrentPage(newPage)}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onRowsPerPageChange={(event) => {
                      const newRowsPerPage = parseInt(event.target.value, 10);
                      setPageSize(newRowsPerPage);
                    }}
                  />
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card>
                  <Typography m={2} variant="h5" color="primary" align="center">
                    لا توجد بيانات
                  </Typography>
                </Card>
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              <Box sx={{ height: "70vh", width: "100%" }}>
                <DataGrid
                  rows={
                    _PointsOfsales.data?.map((x, index) => ({
                      ...x,
                      index: index + 1,
                    })) ?? []
                  }
                  columns={columns}
                  pageSize={pageSize}
                  rowCount={_PointsOfsales.total}
                  loading={_PointsOfsales.loading || _UserReload}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PosTabel;

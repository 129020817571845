import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Orders,
  setOrderPinsDialog,
  setSearchBody,
} from "../../../../../app/slices/orderSlice";
import dayjs from "dayjs";
import { SelectItem } from "../../../../../components/Selectors/SelectItems";
import { SelectSubCategory } from "../../../../../components/Selectors/SelectSubCategory";
import { MdOutlineClear } from "react-icons/md";
import { IoBarcodeOutline } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import { SelectSupplier } from "../../../../../components/Selectors/SelectSupplier";
import OrderPinsTabel from "../order-pins";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import Permission from "../../../../../components/userPermission/userPermission";

const BillsTabel = () => {
  const dispatch = useDispatch();

  const _Bills = useSelector((state) => state.orders.bills);
  const loading = useSelector((state) => state.orders.order.loading);
  const _SearchBody = useSelector((state) => state.orders.searchBody);

  const [search, setSearch] = useState({
    item: null,
    subcategory: null,
    supplier: null,
    id: "",
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Orders.deleteBillById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "id",
      headerName: "رقم الفاتورة",
      flex: 1,
    },
    {
      field: "supplier",
      headerName: "المورد",
      flex: 1,
    },
    {
      field: "items",
      headerName: "معلومات المنتج",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography>
              {params.row.items[0]?.item_name?.toLocaleString()}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "buying_price",
      headerName: "سعر الشراء",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.buying_price?.toLocaleString()}</>;
      },
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.items[0]?.count?.toLocaleString()}</>;
      },
    },
    {
      field: "total_amount",
      headerName: "المبلغ الكلي",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.total_amount?.toLocaleString()}</>;
      },
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>{dayjs(params.row.created_at).format(" HH:mm / YYYY-MM-DD ")}</>
        );
      },
    },
    {
      field: "operations",
      headerName: "تفاصيل الفاتورة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Permission permission="order_view">
              <IconButton
                onClick={() => {
                  dispatch(setOrderPinsDialog(params.row.id));
                }}
              >
                <IoBarcodeOutline />
              </IconButton>
            </Permission>
            <Permission permission="order_delete">
              <Tooltip title="حذف">
                <IconButton
                  color="error"
                  onClick={() => handleDeleteClick(params.row)}
                >
                  <BiTrash />
                </IconButton>
              </Tooltip>
            </Permission>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Orders.getAllBills(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        id: search.id ?? "",
        type: "supplier",
        skip: currentPage * pageSize,
        take: pageSize,
        item_id: search.item ? search.item.id : "",
        supplier_id: search.supplier ? search.supplier.id : "",
        sub_category_id: search.subcategory ? search.subcategory.id : "",
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          المجموع الكلي : {_Bills.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  return (
    <Card>
      <CardContent>
        <OrderPinsTabel />
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من الحذف الفاتورة المرقمة"
            name={dialogData.id}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.id}
              onChange={(e) => {
                setSearch({
                  ...search,
                  id: e.target.value,
                });
              }}
              label="رقم الطلب"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectSupplier
              value={search.supplier}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  supplier: newValue ? newValue : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <SelectItem
              value={search.item}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  item: newValue ? newValue : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <SelectSubCategory
              value={search.subcategory}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  subcategory: newValue ? newValue : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    item: null,
                    subcategory: null,
                    supplier: null,
                    id: "",
                  });
                  setPageSize(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                height: "70vh",
                width: "100%",
              }}
            >
              <DataGrid
                rows={
                  _Bills.data?.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  })) ?? []
                }
                columns={columns}
                pageSize={pageSize}
                page={currentPage}
                rowCount={_Bills.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BillsTabel;

import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Chip,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Product,
  setSearchBody,
} from "../../../../../app/slices/productsSlice";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import { MdOutlineClear } from "react-icons/md";
import { SelectSubCategory } from "../../../../../components/Selectors/SelectSubCategory";
import Permission from "../../../../../components/userPermission/userPermission";

const ProductsTable = () => {
  const dispatch = useDispatch();

  const _Product = useSelector((state) => state.product.products);
  const loading = useSelector((state) => state.product.loading);
  const _SearchBody = useSelector((state) => state.product.searchBody);
  const _SubCategory = useSelector(
    (state) => state.subCategories.subCategoriesList.data
  );

  const [search, setSearch] = useState({
    search: "",
    sub_category_id: null,
  });
  const [page, setPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [openActive, setOpenActive] = useState(false);
  const [dialogActiveData, setDialogActiveData] = useState({});

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Product.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleActiveClick = (params) => {
    setOpenActive(true);
    setDialogActiveData(params);
  };

  const handleConfirmActive = () => {
    dispatch(
      Product.activeProduct(
        dialogActiveData.id,
        {
          is_active: !dialogActiveData.is_active,
        },
        _SearchBody
      )
    );
    setOpenActive(false);
  };

  const handleCancelActive = () => {
    setOpenActive(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "sequence",
      headerName: "التسلسل",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              WebkitLineBreak: "after-white-spaces",
              whiteSpace: "break-spaces",
            }}
          >
            {params.row?.name}
          </Typography>
        );
      },
    },
    {
      field: "sub_category_name",
      headerName: "التصنيف",
      flex: 1,
    },
    {
      field: "selling_price",
      headerName: "سعر البيع",
      flex: 1,
      renderCell: (params) => {
        return <>{parseInt(params.row?.selling_price)?.toLocaleString()}</>;
      },
    },
    {
      field: "revenue",
      headerName: "الربح (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <>{parseInt(params.row?.revenue)?.toLocaleString()}</>;
      },
    },
    {
      field: "is_hidden",
      headerName: "اخفاء المنتج !",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_hidden ? "مخفي" : "مرئي"}
            color={params.row.is_hidden ? "default" : "success"}
          ></Chip>
        );
      },
    },
    {
      field: "is_active",
      headerName: "حالة المنتج !",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip
            label={params.row.is_active ? "فعال" : "غير فعال"}
            color={params.row.is_active ? "success" : "error"}
            onClick={() => {
              handleActiveClick(params.row);
            }}
          ></Chip>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="product_edit">
              <IconButton
                onClick={() => dispatch(Product.getById(params.row.id))}
              >
                <BiEdit />
              </IconButton>
            </Permission>
            <Permission permission="product_delete">
              <IconButton onClick={() => handleDeleteClick(params.row)}>
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Product.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        sub_category_id: search.sub_category_id ?? "",
        skip: currentPage * page,
        take: page,
      })
    );
  }, [currentPage, page, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <ConfirmationDialog
            open={openActive}
            title="تاكيد"
            message={`هل انت متاكد من ${dialogActiveData.is_active ? "الغاء تفعيل المنتج" : "تفعيل المنتج"
              }`}
            name={dialogActiveData.user_name}
            confirmText="تاكيد"
            cancelText="الغاء"
            onConfirm={() => handleConfirmActive()}
            onCancel={handleCancelActive}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              value={search.search}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectSubCategory
              isproduct
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  sub_category_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _SubCategory.filter((x) => x.id == search.sub_category_id)[0]
                  ? _SubCategory.filter(
                    (x) => x.id == search.sub_category_id
                  )[0]
                  : search.sub_category_id
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip title="الغاء الفلتر">
              <IconButton
                onClick={() => {
                  setSearch({
                    search: "",
                    sub_category_id: null,
                  });
                  setPage(15);
                  setCurrentPage(0);
                }}
              >
                <MdOutlineClear />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={_Product.data.filter((product, index) => {
                  return { ...product, index: index + 1 };
                })}
                columns={columns}
                pageSize={page}
                page={currentPage}
                rowCount={_Product.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => setCurrentPage(newPage)}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPage(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductsTable;

import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiEdit, BiTrash } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchBody,
  Cards,
  setDialog,
} from "../../../../../app/slices/cardsSlice";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import Permission from "../../../../../components/userPermission/userPermission";

const CardsTable = () => {
  const dispatch = useDispatch();
  const _Cards = useSelector((state) => state.cards.cards);
  const _SearchBody = useSelector((state) => state.cards.searchBody);
  const [search, setSearch] = useState({
    search: "",
    user_id: null,
    type: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Cards.deleteById(dialogData.id));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "number",
      headerName: "رقم البطاقة",
      flex: 1,
    },
    {
      field: "holder_name",
      headerName: "اسم صاحب البطاقة",
      flex: 1,
    },
    {
      field: "type",
      headerName: "نوع البطاقة",
      flex: 0.5,
    },
    {
      field: "order_issued_id",
      headerName: "رقم طلب الاصدار",
      flex: 0.5,
    },
    {
      field: "issued_at",
      headerName: "تاريخ الاصدار",
      flex: 0.5,
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="card_edit">
              <IconButton onClick={() => dispatch(setDialog(params.row))}>
                <BiEdit />
              </IconButton>
            </Permission>
            <Permission permission="card_delete">
              <IconButton onClick={() => handleDeleteClick(params.row)}>
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Cards.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        user_id: search.user_id ? search.user_id.id : "",
        type: search.type ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.holder_name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={search.search}
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="بحث عن رقم البطاقة"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "63vh", width: "100%" }}>
              {_Cards.data ? (
                <DataGrid
                  rows={_Cards.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_Cards.total}
                  loading={_Cards.loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardsTable;

import Typography from "@mui/material/Typography";
import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  CircularProgress,
  Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit, BiError } from "react-icons/bi";
import { IoMdCloudDone } from "react-icons/io";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Operation,
  setChangeSupplierDialog,
  setDuplicatePinsDialog,
  setSearchBody,
} from "../../../../app/slices/operationSlice";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { SelectSupplier } from "../../../../components/Selectors/SelectSupplier";
import { MdOutlineClear, MdSearch } from "react-icons/md";
import { HiOutlineDocumentDuplicate } from "react-icons/hi2";
import { RiExchangeFundsFill } from "react-icons/ri";
import DuplicatePinsTabel from "./DuplicatePins";
import { setUpdateExcelDialog } from "../../../../app/slices/pinsSlice";
import UpdateExcelPriceForm from "../../form";
import ChangeSupplierForm from "./changeSupplier";
import { SelectItem } from "../../../../components/Selectors/SelectItems";
import Permission from "../../../../components/userPermission/userPermission";

const OperationTabel = () => {
  const dispatch = useDispatch();

  const _Operation = useSelector((state) => state.operation.operations);
  const loading = useSelector((state) => state.operation.operation.loading);
  const _SearchBody = useSelector((state) => state.operation.searchBody);
  const _ChangeSupplier = useSelector(
    (state) => state.operation.changeSupplier.dialog
  );
  const _Supplier = useSelector((state) => state.supplier.suppliersList.data);

  const [page, setPage] = useState(_SearchBody.take || 15);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [operationId, setOperationId] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Operation.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "id",
      headerName: "رقم العملية",
      flex: 0.6,
    },
    {
      field: "total_amount",
      headerName: "المبلغ الكلي (IQD)",
      flex: 0.8,
      renderCell: (params) => {
        return <>{params.row.total_amount.toLocaleString()}</>;
      },
    },
    {
      field: "pins",
      headerName: "الارقام الصحيحة",
      flex: 0.7,
    },
    {
      field: "duplicate_pin",
      headerName: "الارقام المرفوضة",
      flex: 0.6,
    },
    {
      field: "item",
      headerName: "المنتج",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.item ? params.row.item.name : ""}</>;
      },
    },
    {
      field: "buying_price",
      headerName: "سعر الشراء",
      flex: 0.7,
      renderCell: (params) => {
        return <>{params.row.buying_price?.toLocaleString()}</>;
      },
    },
    {
      field: "supplier",
      headerName: "المورد",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.supplier ? params.row.supplier.name : ""}</>;
      },
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack spacing={0.5}>
            <Typography variant="body">
              {dayjs(params.row.created_at).format("YYYY-MM-DD")}
            </Typography>
            <Typography variant="body">
              {dayjs(params.row.created_at).format("hh:mm:ss a")}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "excel_status",
      headerName: "الرفع",
      flex: 0.5,
      renderCell: (params) =>
        params.row.excel_status === 1 ? (
          <CircularProgress size={25} color="info" />
        ) : params.row.excel_status === 0 ? (
          <IoMdCloudDone size={25} color="#388e3c" />
        ) : (
          <Tooltip title={params.row.error_message}>
            <IconButton>
              <BiError size={25} color="#ff0000" />
            </IconButton>
          </Tooltip>
        ),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="operation_duplicate_view">
              <Tooltip title="الكودات المكررة">
                <IconButton
                  onClick={() => {
                    dispatch(setDuplicatePinsDialog());
                    setOperationId(params.row.id);
                  }}
                >
                  <HiOutlineDocumentDuplicate />
                </IconButton>
              </Tooltip>
            </Permission>
            <Permission permission="operation_edit">
              <Tooltip title="تغيير المورد">
                <IconButton
                  onClick={() => dispatch(setChangeSupplierDialog(params.row.id))}
                  color="info"
                >
                  <RiExchangeFundsFill />
                </IconButton>
              </Tooltip>
            </Permission>
            <Permission permission="operation_edit">
              <Tooltip title="تعديل">
                <IconButton
                  onClick={() => dispatch(setUpdateExcelDialog(params.row.id))}
                >
                  <BiEdit />
                </IconButton>
              </Tooltip>
            </Permission>
            <Permission permission="operation_delete">
              <Tooltip title="حذف">
                <IconButton
                  color="error"
                  onClick={() => handleDeleteClick(params.row)}
                >
                  <BiTrash />
                </IconButton>
              </Tooltip>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(Operation.getAll(_SearchBody));
    const interval = setInterval(() => {
      dispatch(Operation.getAll(_SearchBody));
    }, 15000);
    return () => {
      clearTimeout(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearch]);

  return (
    <Card>
      <CardContent>
        <DuplicatePinsTabel operationId={operationId} />
        <UpdateExcelPriceForm />
        {_ChangeSupplier && <ChangeSupplierForm />}
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من الحذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={_SearchBody.operation_id}
              onChange={(e) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    operation_id: e.target.value,
                  })
                );
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIsSearch(!isSearch);
                }
              }}
              label="الرقم العملية"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={_SearchBody.serial_number}
              onChange={(e) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    serial_number: e.target.value,
                  })
                );
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIsSearch(!isSearch);
                }
              }}
              label="الرقم التسلسلي "
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              value={_SearchBody.pin_number}
              onChange={(e) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    pin_number: e.target.value,
                  })
                );
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setIsSearch(!isSearch);
                }
              }}
              label=" الرقم السري"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectSupplier
              onChange={(e, newValue) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    supplier_id: newValue ? newValue.id : null,
                  })
                );
                setIsSearch(!isSearch);
              }}
              value={
                _Supplier.filter((x) => x.id === _SearchBody.supplier_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={2}>
            <SelectItem
              value={_SearchBody.item_id}
              onChange={(e, newValue) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    item_id: newValue ? newValue.id : null,
                  })
                );
                setIsSearch(!isSearch);
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction="row" spacing={0}>
              <Tooltip title="بحث">
                <IconButton
                  onClick={() => {
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                      })
                    );
                    setIsSearch(!isSearch);
                  }}
                >
                  <MdSearch />
                </IconButton>
              </Tooltip>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setPage(15);
                    dispatch(
                      setSearchBody({
                        pin_number: "",
                        serial_number: "",
                        supplier_id: null,
                        operation_id: "",
                        skip: 0,
                        take: 15,
                      })
                    );
                    setIsSearch(!isSearch);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          {/* <Grid item xs={1} display='flex' justifyContent='end'>
            <Tooltip title="الوقت المتبقي لاعادة تحديث الصفحة">
              <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
                <Typography><RxLapTimer size={18} /></Typography>
                <Typography>{seconds}</Typography>
              </Stack>
            </Tooltip>
          </Grid> */}
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_Operation.data ? (
                <DataGrid
                  rows={
                    _Operation.data?.map((x, index) => ({
                      ...x,
                      index: index + 1,
                    })) ?? []
                  }
                  columns={columns}
                  pageSize={page}
                  page={_SearchBody.page ?? 0}
                  rowCount={_Operation.total}
                  loading={loading}
                  rowHeight={60}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                        skip: newPage * page,
                        page: newPage,
                      })
                    );
                    setIsSearch(!isSearch);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPage(newPageSize);
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                        take: newPageSize,
                      })
                    );
                    setIsSearch(!isSearch);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OperationTabel;

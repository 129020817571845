import { useEffect, useState } from "react";
import {
  Box,
  useMediaQuery,
  Drawer,
  Skeleton,
  Stack,
  Chip,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { setDrawer } from "../../../app/slices/appSlice";
import { FiUsers } from "react-icons/fi";
import { BsCardHeading, BsFillSafeFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { BiBarcodeReader, BiCategoryAlt, BiTransferAlt } from "react-icons/bi";
import { TbBuildingWarehouse, TbSubtask } from "react-icons/tb";
import { BsFolder2Open } from "react-icons/bs";
import {
  MdAttachMoney,
  MdOutlineDevices,
  MdOutlinePointOfSale,
  MdPointOfSale,
  MdSettingsSuggest,
} from "react-icons/md";
import {
  AiOutlineGroup,
  AiFillShop,
  AiOutlineBarChart,
  AiOutlineHome,
} from "react-icons/ai";
import { RiAdvertisementLine } from "react-icons/ri";
import { BiImport } from "react-icons/bi";
import { BsGear, BsCashStack } from "react-icons/bs";
import {
  MdNotificationsActive,
  MdMoney,
  MdSupportAgent,
  MdLocationOn,
} from "react-icons/md";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import {
  FaSellsy,
  FaWarehouse,
  FaChartPie,
  FaSms,
  FaFileInvoiceDollar,
  FaIdCardAlt,
  FaMoneyBillWaveAlt,
  FaBoxOpen,
  FaWindowClose,
  FaRegCalendarAlt,
} from "react-icons/fa";
import { TbReportAnalytics } from "react-icons/tb";
import { RiBillLine } from "react-icons/ri";
import {
  GiBoxUnpacking,
  GiExpense,
  GiFlatPlatform,
  GiMoneyStack,
  GiProfit,
  GiTakeMyMoney,
} from "react-icons/gi";
import Logo from "../../../assets/Logo";
import { IoTicketOutline } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { MdRequestQuote } from "react-icons/md";
import { openStatistics } from "../../../app/slices/notificationSlice";
const SideBar = () => {
  const _Role = useSelector((state) => state.user.userInfo.user_roles);
  const _Permissions = useSelector((state) => state.user.userInfo.permissions);
  const userInfoLoading = useSelector((state) => state.user.userInfoLoading);
  const openStatistic = useSelector((state) => state.notification.open);
  //uses
  const theme = useTheme();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (_Permissions.filter((x) => x.name === "notification_view").length > 0) {
      dispatch(openStatistics());
      const interval = setInterval(() => {
        dispatch(openStatistics());
      }, 120000);
      return () => {
        clearTimeout(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //constants
  const drawerWidth = 220;

  //states
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  //selectors and media queries
  const drawerOpen = useSelector((state) => state.app.is_drawer_opened);
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  //functions
  const links = [
    {
      id: 1,
      name: "الرئيسية",
      icon: <AiOutlineHome size={18} />,
      path: "/app",
      sub: [],
      roles: ["super_admin"],
      permissions: ["dashboard_view"],
    },
    {
      id: 9,
      name: "الاحصائيات",
      icon: <FaFileInvoiceDollar size={18} />,
      path: "/app/statistics",
      roles: ["super_admin", "admin", "team_leader"],
      permissions: ["statistics_view"],
      sub: [
        {
          id: 1,
          name: "احصائيات النقاط",
          icon: <FaFileInvoiceDollar size={18} />,
          path: "/app/statistics/pos_orders",
          roles: ["super_admin", "admin", "team_leader"],
          permissions: ["statistics_view"],
        },
        {
          id: 2,
          name: "احصائيات المندوبين",
          icon: <RiBillLine size={18} />,
          path: "/app/statistics/rep_orders",
          roles: ["super_admin", "admin", "team_leader"],
          permissions: ["statistics_view"],
        },
      ],
    },
    {
      id: 2,
      name: " المستخدمين",
      icon: <FiUsers size={18} />,
      path: "/app/users",
      roles: ["super_admin", "admin"],
      permissions: ["user_view"],
      sub: [],
    },
    {
      id: 3,
      name: "التذاكر",
      icon: <IoTicketOutline size={18} />,
      path: "/app/tickets",
      roles: ["super_admin", "team_leader", "admin"],
      permissions: ["ticket_view"],
      sub: [],
    },
    {
      id: 3,
      name: "نقاط البيع",
      icon: <MdPointOfSale size={18} />,
      path: "/app/pos",
      roles: ["super_admin", "team_leader", "representative", "admin"],
      permissions: ["pos_view"],
      sub: [],
    },
    {
      id: 4,
      name: "طلبات نقاط البيع",
      icon: <AiFillShop size={18} />,
      path: "/app/request_points",
      roles: ["super_admin", "team_leader", "representative", "admin"],
      permissions: ["order_recharge_card_view"],
      sub: [],
    },
    {
      id: 14,
      name: "النقاط الغير مسجلة",
      icon: <FaWindowClose size={18} />,
      path: "/app/none_pos",
      roles: ["super_admin", "admin"],
      permissions: ["none_pos_view"],
      sub: [],
    },
    {
      id: 5,
      name: "المواد",
      icon: <BsCardHeading size={18} />,
      path: "/app/items",
      roles: ["super_admin", "admin"],
      permissions: ["item_edit"],
      sub: [],
    },
    {
      id: 6,
      name: "الارصدة والديون",
      icon: <MdMoney size={18} />,
      path: "/app/wallets",
      roles: ["super_admin", "team_leader", "representative", "admin"],
      permissions: ["user_view"],
      sub: [],
    },
    {
      id: 7,
      name: "الاشعارات",
      icon: <MdNotificationsActive size={18} />,
      path: "/app/notification",
      roles: ["super_admin", "team_leader", "representative", "admin"],
      permissions: ["notification_view"],
      sub: [],
    },
    {
      id: 8,
      name: "المبيعات وتحويل الارصدة",
      icon: <FaSellsy size={18} />,
      path: "/app/orders",
      roles: ["super_admin", "admin"],
      permissions: ["order_view", "order_transfer_view"],
      sub: [],
    },
    {
      id: 8,
      name: "المصاريف",
      icon: <GiExpense size={18} />,
      path: "/app/expense",
      roles: ["super_admin", "admin"],
      permissions: ["spending_view"],
      sub: [],
    },
    {
      id: 8,
      name: "الارباح الخارجية",
      icon: <GiTakeMyMoney size={18} />,
      path: "/app/external_revenue",
      roles: ["super_admin", "admin"],
      permissions: ["revenue_view"],
      sub: [],
    },
    {
      id: 8,
      name: "فواتير الاسيا",
      icon: <FaMoneyBillWaveAlt size={18} />,
      path: "/app/asia_bills",
      roles: ["asiacell"],
      permissions: [""],
      sub: [],
    },
    // {
    //   id: 8,
    //   name: "سيم كارت",
    //   icon: <FaSimCard size={18} />,
    //   path: "/app/sim_card",
    //   sub: [],
    //   roles: ["super_admin", "admin"]
    // },
    // {
    //   id: 8,
    //   name: "عمليات التحويل",
    //   icon: <BiTransferAlt size={18} />,
    //   path: "/app/operation_transfer",
    //   sub: [],
    //   roles: ["super_admin", "admin"]
    // },
    {
      id: 9,
      name: "تحويل الارصدة",
      icon: <BiTransferAlt size={18} />,
      path: "/app/sim_card_transfer",
      roles: ["super_admin", "admin"],
      permissions: ["operation_transfer_view", "sim_card_view"],
      sub: [
        {
          id: 1,
          name: "سيم كارت",
          icon: <BiImport size={18} />,
          path: "/app/sim_card_transfer/sim_card",
          roles: ["super_admin", "admin"],
          permissions: ["sim_card_view"],
        },
        {
          id: 2,
          name: "عمليات التحويل",
          icon: <RiBillLine size={18} />,
          path: "/app/sim_card_transfer/operation_transfer",
          roles: ["super_admin", "admin"],
          permissions: ["operation_transfer_view"],
        },
        {
          id: 2,
          name: "الرسائل المستلمة",
          icon: <FaSms size={18} />,
          path: "/app/sim_card_transfer/received_sms",
          roles: ["super_admin", "admin"],
          permissions: [""],
        },
      ],
    },
    {
      id: 9,
      name: "الموردين والفواتير",
      icon: <BiImport size={18} />,
      path: "/app/suppliers&bills",
      roles: ["super_admin", "admin"],
      permissions: ["supplier_view"],
      sub: [
        {
          id: 1,
          name: "الموردين",
          icon: <BiImport size={18} />,
          path: "/app/suppliers&bills/suppliers",
          roles: ["super_admin", "admin"],
          permissions: ["supplier_view"],
        },
        {
          id: 1,
          name: "الممولين",
          icon: <GiTakeMyMoney size={18} />,
          path: "/app/suppliers&bills/Investors",
          roles: ["super_admin", "admin"],
          permissions: ["investors_view"],
        },
        {
          id: 2,
          name: "الفواتير",
          icon: <RiBillLine size={18} />,
          path: "/app/suppliers&bills/bills",
          roles: ["super_admin", "admin"],
          permissions: ["order_view"],
        },
      ],
    },
    {
      id: 10,
      name: "الارقام السرية",
      icon: <BiBarcodeReader size={18} />,
      path: "/app/pins",
      roles: ["super_admin", "admin"],
      permissions: ["pin_view"],
      sub: [
        {
          id: 1,
          name: "الارقام السرية",
          icon: <BiBarcodeReader size={18} />,
          path: "/app/pins/pins",
          roles: ["super_admin", "admin"],
          permissions: ["pin_view", "operation_view"],
        },
        {
          id: 2,
          name: "العمليات",
          icon: <HiOutlineClipboardDocumentList size={18} />,
          path: "/app/pins/operations",
          roles: ["super_admin", "admin"],
          permissions: ["operation_view"],
        },
      ],
    },
    {
      id: 8,
      name: "الدفع الالكتروني",
      icon: <FaMoneyCheckAlt size={18} />,
      path: "/app/payments",
      roles: ["super_admin", "admin"],
      permissions: [
        "e_payment_view",
        "card_view",
        "credit_card_view",
        "issued_card_view",
      ],
      sub: [
        {
          id: 1,
          name: "البطاقات",
          icon: <MdOutlinePayment size={18} />,
          path: "/app/payments/cards",
          roles: ["super_admin", "admin"],
          permissions: ["card_view"],
        },
        {
          id: 2,
          name: "طلبات شحن البطاقات",
          icon: <MdRequestQuote size={18} />,
          path: "/app/payments/order-recharge-cards",
          roles: ["super_admin", "admin"],
          permissions: ["order_recharge_card_view"],
        },
        {
          id: 3,
          name: "طلبات اصدار البطاقات",
          icon: <FaIdCardAlt size={18} />,
          path: "/app/payments/issued_card",
          roles: ["super_admin", "admin"],
          permissions: ["issued_card_view"],
        },
        {
          id: 3,
          name: "المعاملات المالية",
          icon: <MdAttachMoney size={18} />,
          path: "/app/payments/epayment_transactions",
          roles: ["super_admin", "admin"],
          permissions: ["e_payment_view"],
        },
      ],
    },
    {
      id: 11,
      name: "الفئات",
      icon: <BiCategoryAlt size={18} />,
      path: "/app/pages",
      roles: ["super_admin", "admin"],
      permissions: ["category_view", "sub_category_view"],
      sub: [
        {
          id: 1,
          name: "الفئات الرئيسية",
          icon: <BsFolder2Open size={18} />,
          path: "/app/pages/categories",
          roles: ["super_admin", "admin"],
          permissions: ["category_view"],
        },
        {
          id: 2,
          name: "الفئات الثانوية",
          icon: <TbSubtask size={18} />,
          path: "/app/pages/sub_categories",
          roles: ["super_admin", "admin"],
          permissions: ["sub_category_view"],
        },
      ],
    },
    {
      id: 12,
      name: "الادارة",
      icon: <BsGear size={18} />,
      path: "/app/management",
      roles: ["super_admin", "admin"],
      permissions: [
        "group_view",
        "banner_view",
        "pos_type_view",
        "pos_field_view",
      ],
      sub: [
        {
          id: 1,
          name: "المجموعات ",
          icon: <AiOutlineGroup size={18} />,
          path: "/app/management/groups",
          roles: ["super_admin", "admin"],
          permissions: ["group_view"],
        },
        {
          id: 2,
          name: "الاعلانات",
          icon: <RiAdvertisementLine size={18} />,
          path: "/app/management/banner",
          roles: ["super_admin", "admin"],
          permissions: ["banner_view"],
        },
        {
          id: 2,
          name: "فئات نقاط البيع",
          icon: <BiCategoryAlt size={18} />,
          path: "/app/management/pos_types",
          roles: ["super_admin", "admin"],
          permissions: ["pos_type_view"],
        },
        {
          id: 2,
          name: "النشاط التجاري للنقاط",
          icon: <GiMoneyStack size={18} />,
          path: "/app/management/pos_field",
          roles: ["super_admin", "admin"],
          permissions: ["pos_field_view"],
        },
        {
          name: "المحافظات والمدن",
          icon: <MdLocationOn />,
          path: "/app/management/provinces",
          roles: ["super_admin", "admin"],
          permissions: [""],
        },
      ],
    },
    {
      id: 13,
      name: "التقارير",
      icon: <TbReportAnalytics size={18} />,
      path: "/app/reports",
      roles: ["super_admin", "asiacell", "team_leader", "admin"],
      permissions: ["reports_view", "statistics_view"],
      sub: [
        {
          id: 1,
          name: "تفاصيل المندوبين",
          icon: <MdSupportAgent size={18} />,
          path: "/app/reports/representative_details",
          roles: ["super_admin", "admin"],
          permissions: ["reports_view"],
        },
        {
          id: 1,
          name: "المبيعات",
          icon: <AiOutlineBarChart size={18} />,
          path: "/app/reports/orders",
          roles: ["super_admin", "asiacell", "team_leader"],
          permissions: ["reports_view"],
        },
        {
          id: 1,
          name: "الارباح",
          icon: <GiProfit size={18} />,
          path: "/app/reports/total_revenue",
          roles: ["super_admin"],
          permissions: ["reports_view"],
        },
        {
          id: 1,
          name: "الكاش",
          icon: <BsCashStack size={18} />,
          path: "/app/reports/cash",
          roles: ["super_admin", "admin"],
          permissions: ["reports_view"],
        },
        {
          id: 1,
          name: "الاحصائيات النهائية",
          icon: <FaChartPie size={18} />,
          path: "/app/reports/totals",
          roles: ["super_admin", "admin"],
          permissions: ["statistics_view"],
        },
        {
          id: 1,
          name: "المخزن",
          icon: <FaWarehouse size={18} />,
          path: "/app/reports/stock",
          roles: ["super_admin", "asiacell", "admin"],
          permissions: [""],
        },
        {
          id: 1,
          name: "دفعات المنصة",
          icon: <BsCashStack size={18} />,
          path: "/app/reports/platform_payments",
          roles: ["super_admin", "admin"],
          permissions: ["reports_view"],
        },
      ],
    },
    {
      id: 12,
      name: "المنصة",
      icon: <GiFlatPlatform size={18} />,
      path: "/app/platform",
      roles: ["super_admin", "admin"],
      permissions: ["product_view", "supplier_product_view"],
      sub: [
        {
          id: 1,
          name: "الاجهزة",
          icon: <MdOutlineDevices size={18} />,
          path: "/app/platform/products",
          roles: ["super_admin", "admin"],
          permissions: ["product_view"],
        },
        {
          id: 2,
          name: "المجهزين",
          icon: <TbBuildingWarehouse size={18} />,
          path: "/app/platform/product_supplier",
          roles: ["super_admin", "admin"],
          permissions: ["supplier_product_view"],
        },
        {
          id: 2,
          name: "الطلبات",
          icon: <FaSellsy size={18} />,
          path: "/app/platform/products_orders",
          roles: ["super_admin", "admin"],
          permissions: ["supplier_product_view"],
        },
      ],
    },
    {
      id: 11,
      name: "الجملة",
      icon: <BiCategoryAlt size={18} />,
      path: "/app/wholesale",
      roles: [
        "super_admin",
        "admin",
        "team_leader",
        "representative",
        "wholesale",
      ],
      permissions: [
        "j_pos_view",
        "j_group_view",
        "j_category_view",
        "j_sub_category_view",
        "j_item_view",
        "j_supplier_view",
        "j_invoice_view",
        "j_order_view",
        "j_monthly_target_view",
        "j_report_view",
        "safe_view",
      ],
      sub: [
        {
          id: 5,
          name: "نقاط البيع",
          icon: <MdOutlinePointOfSale size={18} />,
          path: "/app/wholesale/j_pos",
          roles: ["super_admin", "admin", "wholesale"],
          permissions: ["j_pos_view"],
        },
        {
          id: 1,
          name: "المجموعات",
          icon: <AiOutlineGroup size={18} />,
          path: "/app/wholesale/group",
          roles: ["super_admin", "admin", "wholesale"],
          permissions: ["j_group_view"],
        },
        {
          id: 1,
          name: "الاقسام",
          icon: <BsFolder2Open size={18} />,
          path: "/app/wholesale/categories",
          roles: ["super_admin", "admin", "wholesale"],
          permissions: ["j_category_view", "j_sub_category_view"],
        },
        {
          id: 2,
          name: "المنتجات",
          icon: <TbSubtask size={18} />,
          path: "/app/wholesale/items",
          roles: ["super_admin", "admin", "wholesale"],
          permissions: ["j_item_view"],
        },
        {
          id: 3,
          name: "الموردين",
          icon: <FaBoxOpen size={18} />,
          path: "/app/wholesale/supplier",
          roles: ["super_admin", "admin", "wholesale"],
          permissions: ["j_supplier_view"],
        },
        {
          id: 4,
          name: "فواتير الشراء",
          icon: <GiTakeMyMoney size={18} />,
          path: "/app/wholesale/invoice",
          roles: ["super_admin", "admin", "wholesale"],
          permissions: ["j_invoice_view"],
        },
        {
          id: 6,
          name: "الطلبات",
          icon: <GiBoxUnpacking size={18} />,
          path: "/app/wholesale/orders",
          roles: [
            "super_admin",
            "admin",
            "team_leader",
            "representative",
            "wholesale",
          ],
          permissions: ["j_order_view"],
        },
        {
          id: 6,
          name: "الهدف الشهري للمندوبين",
          icon: <FaRegCalendarAlt size={18} />,
          path: "/app/wholesale/monthly_target",
          roles: ["super_admin", "admin"],
          permissions: ["j_monthly_target_view"],
        },
        {
          id: 6,
          name: "الخزنات",
          icon: <BsFillSafeFill size={18} />,
          path: "/app/wholesale/safe",
          roles: ["super_admin", "admin", "wholesale"],
          permissions: ["safe_view"],
        },
        {
          id: 6,
          name: "المصاريف",
          icon: <GiExpense size={18} />,
          path: "/app/wholesale/expense",
          roles: ["super_admin", "admin", "wholesale"],
          permissions: ["j_spending_view"],
        },
        {
          id: 13,
          name: "التقارير",
          icon: <TbReportAnalytics size={18} />,
          path: "/app/wholesale/reports",
          roles: ["super_admin", "team_leader", "admin", "wholesale"],
          permissions: ["j_report_view"],
        },
      ],
    },
    {
      id: 8,
      name: "الاعدادات",
      icon: <MdSettingsSuggest size={18} />,
      path: "/app/settings",
      sub: [],
      roles: ["super_admin"],
      permissions: [""],
    },
  ];

  const hasRole = (itemRoles, userRoles) => {
    return itemRoles.some((role) => userRoles.includes(role));
  };

  const hasPermission = (permissions, requiredPermissions) => {
    return requiredPermissions.some((permission) =>
      permissions.some((userPermission) => userPermission.name === permission)
    );
  };

  const drawerSubLinks = (link, index) => {
    return link.sub.length > 0 ? (
      <Collapse
        in={
          (open && index === selectedID) ||
          link.sub?.filter((item) => item?.path === pathname)[0]?.path ===
            pathname ||
          _Role.includes("asiacell")
        }
        timeout="auto"
        unmountOnExit
      >
        <List dense={true} component="div" disablePadding>
          {link.sub.map((item, subIndex) => {
            const hasRoleForSub = hasRole(item.roles, _Role);
            const hasPermForSub = hasPermission(
              _Permissions,
              item.permissions || []
            );

            if (hasRoleForSub || hasPermForSub) {
              return (
                <ListItemButton
                  key={subIndex}
                  sx={{
                    pl: 4,
                    marginTop: 0.5,
                    backgroundColor:
                      item.path === pathname
                        ? theme.palette.primary.light
                        : "transparent",
                    color: theme.palette.primary.main,
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    navigate(item.path);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "30px",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              );
            }
            return null;
          })}
        </List>
      </Collapse>
    ) : null;
  };
  const drawerLinks = (
    <div>
      {/* <Toolbar /> */}
      <Box display="flex" justifyContent="center" margin={1}>
        {/* <img src={logo_light} width={50} height={50} /> */}
      </Box>
      {/* <Divider /> */}
      <List
        dense={false}
        sx={{
          "& .MuiListItem-root:hover": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
          },
          "& .Mui-selected": {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            borderRadius: "8px",
            fontWeight: "bold",
          },
        }}
      >
        {links.map((item, index) => {
          const hasRoleForItem = hasRole(item.roles, _Role);
          const hasPermForItem = hasPermission(
            _Permissions,
            item.permissions || []
          );

          if (hasRoleForItem || hasPermForItem) {
            return (
              <div key={index}>
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    key={index}
                    selected={item.path === "/app" + pathname.split("/app")[1]}
                    onClick={() => {
                      if (item.sub.length === 0) navigate(item.path);
                      if (index === selectedID) {
                        setOpen(!open);
                      } else {
                        setOpen(true);
                        setSelectedID(index);
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        fontSize: "0.8rem",
                      }}
                      disableTypography={true}
                      primary={item.name}
                    />
                    {openStatistic?.open_tickets > 0 &&
                    item.path === "/app/tickets" ? (
                      <Chip
                        size="small"
                        label={openStatistic?.open_tickets}
                        color="primary"
                      />
                    ) : null}
                    {openStatistic?.j_new_orders > 0 &&
                    (item.path === "/app/wholesale" ||
                      item.path === "/app/wholesale/orders") ? (
                      <Chip
                        size="small"
                        label={openStatistic?.j_new_orders}
                        color="primary"
                        sx={{ mr: 0.5 }}
                      />
                    ) : null}
                    {openStatistic?.card_recharge > 0 &&
                    (item.path === "/app/payments/order-recharge-cards" ||
                      item.path === "/app/payments") ? (
                      <Chip
                        size="small"
                        label={openStatistic?.card_recharge}
                        color="primary"
                      />
                    ) : null}
                    {item.sub.length > 0 ? (
                      open && index === selectedID ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    ) : (
                      ""
                    )}
                  </ListItemButton>
                </ListItem>
                {drawerSubLinks(item, index)}
              </div>
            );
          }
          return null;
        })}
      </List>
    </div>
  );
  const drawerViewAndScroll = (
    <>
      {/* sx={{ display: { xs: "block", md: "none" } }} */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: 70,
          marginBottom: 2,
        }}
      >
        <Logo style={{ width: 120, height: 120, objectFit: "contain" }} />
      </Box>
      <Box>
        {userInfoLoading ? (
          <Stack direction="column" spacing={1} sx={{ margin: 3 }}>
            <Skeleton animation="wave" sx={{ marginBottom: 1 }} />
            <Skeleton animation="pulse" sx={{ marginBottom: 1 }} />
            <Skeleton sx={{ marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ marginBottom: 1 }} />
            <Skeleton animation="pulse" sx={{ marginBottom: 1 }} />
            <Skeleton sx={{ marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ marginBottom: 1 }} />
            <Skeleton animation="pulse" sx={{ marginBottom: 1 }} />
            <Skeleton sx={{ marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ marginBottom: 1 }} />
            <Skeleton animation="pulse" sx={{ marginBottom: 1 }} />
            <Skeleton sx={{ marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ marginBottom: 1 }} />
            <Skeleton animation="pulse" sx={{ marginBottom: 1 }} />
            <Skeleton sx={{ marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ marginBottom: 1 }} />
            <Skeleton animation="pulse" sx={{ marginBottom: 1 }} />
            <Skeleton sx={{ marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ marginBottom: 1 }} />
            <Skeleton animation="pulse" sx={{ marginBottom: 1 }} />
            <Skeleton sx={{ marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ marginBottom: 1 }} />
            <Skeleton animation="pulse" sx={{ marginBottom: 1 }} />
            <Skeleton sx={{ marginBottom: 1 }} />
            <Skeleton animation="wave" sx={{ marginBottom: 1 }} />
            <Skeleton animation="pulse" sx={{ marginBottom: 1 }} />
            <Skeleton sx={{ marginBottom: 1 }} />
          </Stack>
        ) : (
          <Box>
            <BrowserView>
              <PerfectScrollbar
                component="div"
                style={{
                  height: !matchUpMd
                    ? "calc(100vh - 56px)"
                    : "calc(100vh - 88px)",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {drawerLinks}
              </PerfectScrollbar>
            </BrowserView>
            <MobileView>
              <Box sx={{ px: 2 }}>{drawerLinks}</Box>
            </MobileView>
          </Box>
        )}
      </Box>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="sidebar"
    >
      <Drawer
        // container={container}
        dir="rtl"
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(setDrawer())}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            // background: theme.palette.primary.main,
            // background: "gray",
            // color: "white",
            borderRight: "none",

            // [theme.breakpoints.up("md")]: {
            //   top: "88px",
            // },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawerViewAndScroll}
      </Drawer>
    </Box>
  );
};

export default SideBar;

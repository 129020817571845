import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Chip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Categories, setSearchBody,
} from "../../../../../app/slices/categorySlice";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import Permission from "../../../../../components/userPermission/userPermission";

const CategoriesTabel = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(15);
  const dispatch = useDispatch();
  const category = useSelector((state) => state.categories.category);
  const _SearchBody = useSelector((state) => state.categories.searchBody);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params)
  };

  const handleConfirmDelete = () => {
    dispatch(Categories.deleteById(dialogData.id, _SearchBody))
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "sub_categories_count",
      headerName: "عدد الفئات الثانوية",
      flex: 1,
    },
    {
      field: "is_transfer",
      headerName: "تحويل الرصيد؟",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip size="small" label={params.row.is_transfer ? "نعم" : "كلا"} />
        );
      },
    },
    {
      field: "is_product",
      headerName: "المنصة؟",
      flex: 1,
      renderCell: (params) => {
        return (
          <Chip size="small" label={params.row.is_product ? "نعم" : "كلا"} />
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="category_edit">
              <IconButton
                onClick={() => dispatch(Categories.getById(params.row.id))}
              >
                <BiEdit />
              </IconButton>
            </Permission>
            <Permission permission="category_delete">
              <IconButton
                onClick={() => handleDeleteClick(params.row)}
              >
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Categories.getAll({ search: search, skip: 0, take: 15 }));
      dispatch(setSearchBody({ search: search, skip: 0, take: 15 }))
    }, 500);
    return () => clearTimeout(timeout);
  }, [search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {category.data ? (
                <DataGrid
                  rows={category.data}
                  columns={columns}
                  pageSize={page}
                  rowCount={category.total}
                  loading={category.loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) =>
                    dispatch(
                      Categories.getAll({
                        search: search,
                        skip: newPage * 15,
                        take: 15,
                      })
                    )
                  }
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    dispatch(
                      Categories.getAll({
                        search: search,
                        skip: 0,
                        take: newPageSize,
                      })
                    );
                    setPage(newPageSize)
                  }
                  }
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CategoriesTabel;

import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/jomla/invoiceSlice";
import JInvoiceForm from "../../form";
import { GiTakeMyMoney } from "react-icons/gi";
import ChangeSupplierForm from "../../form/changeSupplier";
import Permission from "../../../../../components/userPermission/userPermission";

const JInvoiceHeader = () => {
  const dispatch = useDispatch();
  const JInvoice = useSelector((state) => state.jInvoice);
  const JInvoiceSupplier = useSelector((state) => state.jInvoice);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            فواتير الشراء
          </Typography>
        }
        avatar={<GiTakeMyMoney size={20} />}
        action={
          <Permission permission="j_invoice_edit">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              اضافة فاتورة شراء
            </Button>
          </Permission>
        }
      />
      {JInvoice.jInvoice.dialog ? <JInvoiceForm /> : null}
      {JInvoiceSupplier.changeSupplier.dialog ? <ChangeSupplierForm /> : null}
    </Card>
  );
};

export default JInvoiceHeader;

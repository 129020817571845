import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Typography,
  Autocomplete,
  Stack,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiTrash, BiEdit } from "react-icons/bi";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import {
  JMonthlyTargetActions,
  setSearchBody,
} from "../../../../../app/slices/jomla/monthlyTargetSlice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import useDebounce from "../../../../../components/hooks/useDebounce";
import { JItems } from "../../../../../app/slices/jomla/itemsSlice";
import { SelectRepresentative } from "../../../../../components/Selectors/SelectRepresentative";
import { SelectJItem } from "../../../../../components/Selectors/jomla/SelectJItem";
import { MdOutlineClear, MdSearch } from "react-icons/md";
import Permission from "../../../../../components/userPermission/userPermission";

const JMonthlyTargetTable = () => {
  const dispatch = useDispatch();

  const _JMonthlyTarget = useSelector(
    (state) => state.jMonthlyTarget.jMonthlyTargetData
  );
  const _SearchBody = useSelector((state) => state.jMonthlyTarget.searchBody);
  const _ListSearchBody = useSelector(
    (state) => state.JItems.JItemsList.searchBody
  );
  const _rep = useSelector((state) => state.user.usersList.data);
  const _JItems = useSelector((state) => state.JItems.JItemsList.data);

  const [pageSize, setPageSize] = useState(_SearchBody.take || 15);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const debouncedSearch = useDebounce(_ListSearchBody, 500);

  useEffect(() => {
    dispatch(JItems.getAllList(debouncedSearch));
  }, [debouncedSearch, dispatch]);

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(JMonthlyTargetActions.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const is_achieved = [
    {
      key: "تم تحقيق الهدف",
      value: 1,
    },
    {
      key: "لم يتم تحقيق الهدف",
      value: 0,
    },
  ];

  const columns = [
    {
      field: "user_name",
      headerName: "اسم المندوب",
      flex: 1,
    },
    {
      field: "j_item_name",
      headerName: "اسم المادة",
      flex: 1,
    },
    {
      field: "target",
      headerName: "الهدف",
      flex: 1,
    },
    {
      field: "achieved",
      headerName: "المحقق",
      flex: 1,
    },
    {
      field: "is_achieved",
      headerName: "الحالة",
      flex: 1,
      renderCell: (params) => (
        <Typography color={params.row.is_achieved ? "green" : "error"}>
          {params.row.is_achieved ? "تم تحقيق الهدف" : "لم يتم تحقيق الهدف"}
        </Typography>
      ),
    },
    {
      field: "year",
      headerName: "السنة",
      flex: 1,
    },
    {
      field: "month",
      headerName: "الشهر",
      flex: 1,
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="j_monthly_target_edit">
              <IconButton
                onClick={() =>
                  dispatch(JMonthlyTargetActions.getById(params.row))
                }
              >
                <BiEdit />
              </IconButton>
            </Permission>
            <Permission permission="j_monthly_target_delete">
              <IconButton onClick={() => handleDeleteClick(params.row)}>
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JMonthlyTargetActions.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={'"شهر" and "سنة"'}
                views={["month", "year"]}
                inputFormat="YYYY-MM"
                value={
                  _SearchBody.year
                    ? dayjs().format(`${_SearchBody.year}-${_SearchBody.month}`)
                    : null
                }
                onChange={(e) => {
                  dispatch(
                    setSearchBody({
                      ..._SearchBody,
                      year: dayjs(e).year(),
                      month: dayjs(e).month() + 1,
                    })
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "شهر/سنة",
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectRepresentative
              onChange={(event, value) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    user_id: value ? value.id : null,
                  })
                );
              }}
              value={
                _rep.filter((x) => x.id === _SearchBody.user_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectJItem
              onChange={(e, newValue) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    j_item_id: newValue ? newValue.id : null,
                  })
                );
              }}
              value={
                _JItems.filter((x) => x.id === _SearchBody.j_item_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              options={is_achieved}
              getOptionLabel={(option) => {
                return option.key;
              }}
              onChange={(e, newValue) => {
                dispatch(
                  setSearchBody({
                    ..._SearchBody,
                    is_achieved: newValue ? newValue.value : null,
                  })
                );
              }}
              value={
                is_achieved.filter(
                  (x) => x.value === _SearchBody.is_achieved
                )[0] ?? null
              }
              renderInput={(params) => (
                <TextField {...params} label="تحقيق الهدف" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={1}>
            <Stack direction="row" spacing={0}>
              <Tooltip title="بحث">
                <IconButton
                  onClick={() => {
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                      })
                    );
                  }}
                >
                  <MdSearch />
                </IconButton>
              </Tooltip>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    dispatch(
                      setSearchBody({
                        skip: 0,
                        take: 15,
                        year: dayjs().format("YYYY"),
                        month: dayjs().format("M"),
                        user_id: null,
                        j_item_id: null,
                        is_achieved: null,
                      })
                    );
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_JMonthlyTarget.data ? (
                <DataGrid
                  rows={_JMonthlyTarget.data}
                  columns={columns}
                  pageSize={pageSize}
                  rowCount={_JMonthlyTarget.total}
                  loading={_JMonthlyTarget.loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                        skip: newPage * pageSize,
                        page: newPage,
                      })
                    );
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                        take: newPageSize,
                      })
                    );
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JMonthlyTargetTable;

import { Card, CardHeader, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../app/slices/itemsSlice";
import ItemsForm from "../../form";
import { BsCardHeading } from "react-icons/bs";
import PinsExcelForm from "../../../../components/ExcelDialog";
import PinsCsvForm from "../../../../components/CsvDialog";



const ItemsHeader = () => {
  const _Pins = useSelector((state) => state.pins);
  const _Items = useSelector((state) => state.items);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المواد
          </Typography>
        }
        avatar={<BsCardHeading size={20} />}
        action={
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<IoIosAddCircleOutline />}
            onClick={() => dispatch(setDialog())}
          >
            إضافة منتج جديد
          </Button>
        }
      />
      {_Items.item.dialog ? <ItemsForm /> : null}
      {_Pins.pin.excelDialog ? <PinsExcelForm /> : null}
      {_Pins.pin.csvDialog ? <PinsCsvForm /> : null}
    </Card>
  );
};

export default ItemsHeader;

import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/groupSlice";
import GroupsForm from "../../from";
import { AiOutlineGroup } from "react-icons/ai";
import Permission from "../../../../../components/userPermission/userPermission";

const GroupHeader = () => {
  const _Group = useSelector((state) => state.group);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المجموعات
          </Typography>
        }
        avatar={<AiOutlineGroup size={20} />}
        action={
          <Permission permission="group_edit">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة مجموعة جديدة
            </Button>
          </Permission>
        }
      />
      {_Group.group.dialog ? <GroupsForm /> : null}
    </Card>
  );
};

export default GroupHeader;

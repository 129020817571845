import Typography from "@mui/material/Typography";
import { IconButton, LinearProgress, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Orders, setOrderPinsDialog } from "../../../app/slices/orderSlice";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../helpers/ConfirmationDialog";

import { IoBarcodeOutline } from "react-icons/io5";
import { BiTrash } from "react-icons/bi";
import OrderPinsTable from "./pinsOfOrder";
import Permission from "../../../components/userPermission/userPermission";

const OrdersTable = ({ setPage, setCurrentPage }) => {
  const dispatch = useDispatch();

  const _Orders = useSelector((state) => state.orders.orders);
  const loading = useSelector((state) => state.orders.order.loading);
  const _pinsOfOrder = useSelector((state) => state.orders.pinsOrder.dialog);
  const _SearchBody = useSelector((state) => state.orders.searchBody);

  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Orders.deleteById(dialogData.id));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "id",
      headerName: "رقم الطلب",
      flex: 1,
    },
    {
      field: "pos_name",
      headerName: "اسم نقطة البيع",
      flex: 1,
    },
    {
      field: "itemName",
      headerName: "اسم المنتج",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.item_name}</>;
      },
    },
    {
      field: "count",
      headerName: "العدد",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.item_count}</>;
      },
    },
    {
      field: "discount",
      headerName: "الخصم (IQD)",
      flex: 1,
    },
    {
      field: "total_amount",
      headerName: "السعر الكلي (IQD)",
      flex: 1,
      renderCell: (params) => {
        return <>{params.row.total_amount.toLocaleString()}</>;
      },
    },
    {
      field: "province_name",
      headerName: "المحاقظة",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>{dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD ")}</>
        );
      },
    },
    {
      field: "operations",
      headerName: "تفاصيل الكودات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="order_view">
              <IconButton
                onClick={() => {
                  dispatch(setOrderPinsDialog(params.row.id));
                }}
              >
                <IoBarcodeOutline />
              </IconButton>
            </Permission>
            <Permission permission="order_delete">
              <IconButton
                color="error"
                onClick={() => handleDeleteClick(params.row)}
              >
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Orders.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          المجموع الكلي للمبيعات : {_Orders.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  return (
    <Box>
      {_pinsOfOrder && <OrderPinsTable />}
      <ConfirmationDialog
        open={openConfirm}
        title="تاكيد"
        message="هل انت متاكد من حذف الطلب المرقم"
        name={dialogData.id}
        confirmText="حذف"
        cancelText="الغاء"
        onConfirm={() => handleConfirmDelete()}
        onCancel={handleCancelDelete}
      />
      <Box sx={{ height: "79vh", width: "100%" }}>
        <DataGrid
          rows={
            _Orders.data?.map((x, index) => ({
              ...x,
              index: index + 1,
            })) ?? []
          }
          columns={columns}
          pageSize={_SearchBody.take}
          page={_SearchBody.currentPage}
          rowCount={_Orders.total}
          loading={loading}
          rowHeight={40}
          pagination
          paginationMode="server"
          selectionModel={[]}
          onPageChange={(newPage) => {
            setCurrentPage(newPage);
          }}
          rowsPerPageOptions={[15, 25, 50, 100]}
          onPageSizeChange={(newPageSize) => {
            setPage(newPageSize);
          }}
          components={{
            LoadingOverlay: LinearProgress,
            Footer: CustomFooter,
          }}
        />
      </Box>
    </Box>
  );
};

export default OrdersTable;

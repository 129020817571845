import {
  Button,
  Card,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { MdRequestQuote } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import UpdateCreditCardBalanceForm from "../../form/updateCreditCardBalance";
import {
  OrderRechargeCards,
  setUpdateCreditCardBalanceDialog,
  setUpdateCreditCardTransactionsDialog,
} from "../../../../../app/slices/orderRechargeCardsSlice";
import { AiOutlineTransaction } from "react-icons/ai";
import CreditCardBalanceTransactions from "../../form/creditCardTransctions";
import Permission from "../../../../../components/userPermission/userPermission";

const OrderRechargeCardsHeader = () => {
  const dispatch = useDispatch();

  const _OrderRechargeCard = useSelector(
    (state) => state.orderRechargeCards.updateCreditCardBalance
  );

  useEffect(() => {
    dispatch(OrderRechargeCards.getAllCreditCard());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            طلبات شحن البطاقات
          </Typography>
        }
        avatar={<MdRequestQuote size={20} />}
        action={
          <Stack direction="row" spacing={2} alignItems="center">
            <Permission permission="credit_card_view">
              <Typography variant="h4" color="primary">
                {`رصيد سويتج : ${_OrderRechargeCard.data[0]?.balance?.toLocaleString()} IQD`}
              </Typography>
            </Permission>
            <Tooltip title="المناقلات">
              <IconButton
                color="success"
                variant="contained"
                onClick={() => {
                  dispatch(setUpdateCreditCardTransactionsDialog());
                }}
              >
                <AiOutlineTransaction />
              </IconButton>
            </Tooltip>
            <Permission permission="order_recharge_card_edit">
              <Button
                variant="contained"
                onClick={() => {
                  dispatch(setUpdateCreditCardBalanceDialog());
                }}
              >
                اضافة الرصيد
              </Button>
            </Permission>
          </Stack>
        }
      />
      {_OrderRechargeCard.dialog && <UpdateCreditCardBalanceForm />}
      {_OrderRechargeCard.transactions.dialog && (
        <CreditCardBalanceTransactions />
      )}
    </Card>
  );
};

export default OrderRechargeCardsHeader;

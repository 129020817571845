import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash } from "react-icons/bi";
import { MdOutlineClear } from "react-icons/md";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  OperationTransfer,
  setSearchBody,
} from "../../../../../app/slices/operationTransferSlice";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { SelectSupplier } from "../../../../../components/Selectors/SelectSupplier";
import { SelectSimCard } from "../../../../../components/Selectors/SelectSimCard";
import Permission from "../../../../../components/userPermission/userPermission";

const OperationTransferTable = () => {
  const dispatch = useDispatch();

  const _OperationTransfer = useSelector(
    (state) => state.operationTransfer.operationTransfers
  );
  const loading = useSelector(
    (state) => state.operationTransfer.operationTransfer.loading
  );
  const _SearchBody = useSelector(
    (state) => state.operationTransfer.searchBody
  );

  const [search, setSearch] = useState({
    start_date: dayjs().format("2023-01-01"),
    end_date: dayjs().format("YYYY-MM-DD"),
    simCard: null,
    supplier: null,
    balance: null,
    cashPaid: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(OperationTransfer.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.3,
    },
    {
      field: "id",
      headerName: "رقم العملية",
      flex: 0.5,
    },
    {
      field: "balance",
      headerName: "الرصيد (IQD)",
      flex: 1,
      renderCell: (params) => params.row.balance?.toLocaleString(),
    },
    {
      field: "buying_price",
      headerName: "سعر الشراء (IQD)",
      flex: 1,
      renderCell: (params) => params.row.buying_price?.toLocaleString(),
    },
    {
      field: "cash_paid",
      headerName: "المبلغ المدفوع (IQD)",
      flex: 1,
      renderCell: (params) => params.row.cash_paid?.toLocaleString(),
    },
    {
      field: "total_selling_balance",
      headerName: "مبلغ الرصيد الكلي (IQD)",
      flex: 1,
      renderCell: (params) =>
        params.row.total_selling_balance?.toLocaleString(),
    },
    {
      field: "total_selling_price",
      headerName: "مبلغ البيع الكلي (IQD)",
      flex: 1,
      renderCell: (params) => params.row.total_selling_price?.toLocaleString(),
    },
    {
      field: "sim_card_phone",
      headerName: "رقم الهاتف",
      flex: 1,
      renderCell: (params) => `0${params.row.sim_card_phone}`,
    },
    {
      field: "supplier_name",
      headerName: "اسم المجهز",
      flex: 1,
    },
    {
      field: "created_by",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => (
        <Stack justifyContent='center' alignItems='center'>
          <Typography variant="body">{params.row.created_by}</Typography>
          <Typography variant="body">{dayjs(params.row.created_at).format("YYYY-MM-DD / hh:mm a")}</Typography>
        </Stack>
      )
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="operation_transfer_delete">
              <IconButton onClick={() => handleDeleteClick(params.row)}>
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(OperationTransfer.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        cashPaid: search.cashPaid ?? "",
        balance: search.balance ?? "",
        supplier_id: search.supplier?.id ?? "",
        sim_card_id: search.simCard?.id ?? "",
        start_date: search.start_date ?? "",
        end_date: search.end_date ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف العملية المرقمة"
            name={dialogData.id}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={1.5}>
            <TextField
              size="small"
              value={search.pin_number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  balance: e.target.value,
                });
              }}
              label="الرصيد"
              fullWidth
            />
          </Grid>
          <Grid item xs={1.5}>
            <TextField
              size="small"
              value={search.pin_number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  cashPaid: e.target.value,
                });
              }}
              label="المبلغ المدفوع"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <SelectSupplier
              value={search.supplier}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  supplier: newValue ? newValue : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectSimCard
              value={search.simCard}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  simCard: newValue ? newValue : null,
                });
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      pos_name: "",
                      pos_number: "",
                      status: null,
                      product_supplier_id: null,
                      start_date: dayjs().format("2023-01-01"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_OperationTransfer.data ? (
                <DataGrid
                  rows={_OperationTransfer.data.map((x, index) => {
                    return { ...x, index: index + 1 };
                  })}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_OperationTransfer.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => setCurrentPage(newPage)}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OperationTransferTable;

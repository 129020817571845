import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { BsCardHeading } from "react-icons/bs";
import JItemsForm from "../../form";
import { setDialog } from "../../../../../app/slices/jomla/itemsSlice";
import ItemHistoryDialog from "../../form/itemHistory";
import Permission from "../../../../../components/userPermission/userPermission";

const JItemsHeader = () => {
  const JItems = useSelector((state) => state.JItems);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            المنتجات
          </Typography>
        }
        avatar={<BsCardHeading size={20} />}
        action={
          <Permission permission="j_item_edit">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة منتج جديد
            </Button>
          </Permission>
        }
      />
      {JItems.JItem.dialog ? <JItemsForm /> : null}
      {JItems.jItemHistory.dialog ? <ItemHistoryDialog /> : null}
    </Card>
  );
};

export default JItemsHeader;

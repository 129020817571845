import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Autocomplete,
  Dialog,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Pins,
  setSearchBody,
  setorderDetailsDialog,
} from "../../../../app/slices/pinsSlice";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import { MdOutlineClear, MdSearch } from "react-icons/md";
import { TbReplace } from "react-icons/tb";
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SelectItem } from "../../../../components/Selectors/SelectItems";
import { useParams } from "react-router";
import OrdersDetails from "../details";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import Permission from "../../../../components/userPermission/userPermission";

const ItemsTabel = () => {
  const { item_id } = useParams();

  const dispatch = useDispatch();

  const _Pins = useSelector((state) => state.pins.pins);
  const items = useSelector((state) => state.items.itemsList.data);
  const _SearchBody = useSelector((state) => state.pins.searchBody);
  const _OrderDetails = useSelector((state) => state.pins.orderDetails.dialog);
  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);

  const [pageSize, setPageSize] = useState(_SearchBody.take || 15);
  const [currentPage, setCurrentPage] = useState(_SearchBody.skip || 0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");
  const [pinNumber, setPinNumber] = useState("");
  const [pinId, setPinId] = useState("");
  const [pinReplaceDialog, setPinReplaceDialog] = useState(false);
  const [isSearch, setIsSearch] = useState(false);

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Pins.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const handleReplacePin = () => {
    dispatch(
      Pins.replacePin({
        pin_id: pinId,
        pin_number: pinNumber,
      })
    ).then((x) => {
      setPinNumber("");
      setPinReplaceDialog(false);
    });
  };

  const status = [
    { key: "new", name: "جديد" },
    { key: "sold", name: "مباع" },
    { key: "disabled", name: "معطل" },
  ];

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "serial_number",
      headerName: "الرقم التسلسلي",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.serial_number} followCursor>
          <Typography variant="body" noWrap>
            {params.row.serial_number}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "pin_number",
      headerName: "الرقم السري",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.pin_number} followCursor>
          <Typography variant="body" noWrap>
            {params.row.pin_number}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "operation_id",
      headerName: "رقم العملية",
      flex: 1,
    },
    {
      field: "item_name",
      headerName: "اسم المنتج",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.item_name} followCursor>
          <Typography variant="body" noWrap>
            {params.row.item_name}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "buying_price",
      headerName: "سعر الشراء",
      flex: 1,
      renderCell: (params) => params.row.buying_price?.toLocaleString(),
    },
    {
      field: "status",
      headerName: "الحالة",
      flex: 0.6,
      renderCell: (params) => {
        return (
          <Typography color={params.row.status === "disabled" ? "error" : null}>
            {
              status.filter(
                (status) => params.row.status === status.key ?? status.key
              )[0]?.name
            }
          </Typography>
        );
      },
    },
    {
      field: "order",
      headerName: "معلومات البيع",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            sx={{ textDecoration: "underLine" }}
            onClick={() => dispatch(setorderDetailsDialog(params.row.order_id))}
          >
            {params.row.order_id
              ? params.row.order_id + " - " + params.row.order_to
              : ""}
          </Button>
        );
      },
    },
    {
      field: "date",
      headerName: "تاريخ التحديث",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body" noWrap>
            {dayjs(params.row.date).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="body" noWrap>
            {dayjs(params.row.date).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "expiry_date",
      headerName: "تاريخ النفاذ",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            color={
              dayjs(params.row.expiry_date).format("YYYY-MM-DD") <
                dayjs().format("YYYY-MM-DD")
                ? "error"
                : null
            }
          >
            {dayjs(params.row.expiry_date).format("YYYY-MM-DD")}
          </Typography>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="pin_edit">
              <Tooltip title="تعديل">
                <IconButton onClick={() => dispatch(Pins.getById(params.row.id))}>
                  <BiEdit />
                </IconButton>
              </Tooltip>
            </Permission>
            <Permission permission="pin_edit">
              <Tooltip title="تبديل الكود">
                <IconButton
                  onClick={() => {
                    setPinReplaceDialog(true);
                    setPinId(params.row.id);
                  }}
                >
                  <TbReplace />
                </IconButton>
              </Tooltip>
            </Permission>
            <Permission permission="pin_delete">
              <Tooltip title="حذف">
                <IconButton onClick={() => handleDeleteClick(params.row)}>
                  <BiTrash />
                </IconButton>
              </Tooltip>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Pins.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearch]);

  return (
    <Card>
      <CardContent>
        <Box>
          {_OrderDetails && <OrdersDetails />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.serial_number}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
          <Dialog
            open={pinReplaceDialog}
            keepMounted
            fullWidth={true}
            dir="rtl"
            onClose={() => {
              setPinReplaceDialog(false);
              setPinNumber("");
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <Typography
                mb={3}
                variant="h4"
                color={"purple"}
                gutterBottom
                component="div"
              >
                تبديل الكود
              </Typography>
              {_Pins.loading && <LinearProgress sx={{ my: 1 }} />}
              <Box>
                <TextField
                  size="small"
                  value={pinNumber}
                  onChange={(e) => {
                    setPinNumber(e.target.value);
                  }}
                  label="الرقم السري الجديد "
                  fullWidth
                />
                <Button
                  onClick={handleReplacePin}
                  sx={{ mt: 2 }}
                  variant="contained"
                >
                  حفظ
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {!item_id && (
            <>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  type="number"
                  value={_SearchBody.serial_number}
                  onChange={(e) => {
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                        serial_number: e.target.value,
                      })
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsSearch(!isSearch);
                    }
                  }}
                  label="بحث عن الرقم التسلسلي "
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  value={_SearchBody.pin_number}
                  onChange={(e) => {
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                        pin_number: e.target.value,
                      })
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsSearch(!isSearch);
                    }
                  }}
                  label="بحث عن الرقم السري "
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  options={status}
                  getOptionLabel={(option) => option.name || ""}
                  value={
                    status.filter((x) => x.key === _SearchBody.status)[0] ||
                    _SearchBody.status
                  }
                  onChange={(e, newValue) => {
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                        status: newValue ? newValue.key : "",
                      })
                    );
                    setIsSearch(!isSearch);
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField {...params} label="الحالة" size="small" />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <SelectItem
                  value={
                    items.filter((x) => x.id === _SearchBody.item_id)[0] ?? null
                  }
                  onChange={(e, newValue) => {
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                        item_id: newValue ? newValue.id : "",
                      })
                    );
                    setIsSearch(!isSearch);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  type="number"
                  size="small"
                  value={_SearchBody.operation_id}
                  onChange={(e) => {
                    dispatch(
                      setSearchBody({
                        ..._SearchBody,
                        operation_id: e.target.value,
                      })
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsSearch(!isSearch);
                    }
                  }}
                  label="بحث عن رقم العملية "
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat="YYYY-MM-DD"
                    label="تاريخ النفاذ"
                    value={
                      _SearchBody.expiry_date ? _SearchBody.expiry_date : null
                    }
                    onChange={(e) => {
                      dispatch(
                        setSearchBody({
                          ..._SearchBody,
                          expiry_date: dayjs(e).format("YYYY-MM-DD"),
                        })
                      );
                      setIsSearch(!isSearch);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        fullWidth
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "يوم/شهر/سنة",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2}>
                <Stack direction="row" spacing={0}>
                  <Tooltip title="بحث">
                    <IconButton
                      onClick={() => {
                        dispatch(
                          setSearchBody({
                            ..._SearchBody,
                          })
                        );
                        setIsSearch(!isSearch);
                      }}
                    >
                      <MdSearch />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="الغاء الفلتر">
                    <IconButton
                      onClick={() => {
                        setPageSize(15);
                        setCurrentPage(0);
                        dispatch(
                          setSearchBody({
                            expiry_date: "",
                            operation_id: "",
                            pin_number: "",
                            serial_number: "",
                            item_id: "",
                            status: "",
                            skip: 0,
                            take: 15,
                          })
                        );
                        setIsSearch(!isSearch);
                      }}
                    >
                      <MdOutlineClear />
                    </IconButton>
                  </Tooltip>
                  {_Role === "representative" ? null : (
                    <Tooltip title="تصدير ملف اكسل">
                      <IconButton
                        onClick={() => {
                          dispatch(Pins.exportExcel(_SearchBody));
                        }}
                      >
                        <BsFillFileEarmarkExcelFill />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Box sx={{ height: "65vh", width: "100%" }}>
              <DataGrid
                rows={
                  _Pins.data?.map((x, index) => ({ ...x, index: index + 1 })) ??
                  []
                }
                columns={columns}
                pageSize={pageSize}
                page={_SearchBody.page ?? 0}
                rowCount={_Pins.total}
                loading={_Pins.loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                  dispatch(
                    setSearchBody({
                      ..._SearchBody,
                      skip: newPage * pageSize,
                      page: newPage,
                    })
                  );
                  setIsSearch(!isSearch);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                  dispatch(
                    setSearchBody({
                      ..._SearchBody,
                      take: newPageSize,
                    })
                  );
                  setIsSearch(!isSearch);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ItemsTabel;

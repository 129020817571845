import { useSelector } from "react-redux";

const Permission = ({ permission, role, children }) => {
  const userRoles = useSelector((state) => state.user.userInfo.user_roles);
  const userPermissions = useSelector(
    (state) => state.user.userInfo.permissions
  );

  const hasPermission = userPermissions
    .map((data) => data.name)
    .includes(permission);
  const hasRole = userRoles.includes(role);
  const isSuperAdmin = userRoles.includes("super_admin");
  const isAdmin = userRoles.includes("admin");

  return hasPermission || hasRole || isSuperAdmin || isAdmin ? children : null;
};

export default Permission;

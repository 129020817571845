import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpenseTypes } from "../../../../../app/slices/expenseTypesSlice";
import dayjs from "dayjs";
import { BiTrash, BiEdit } from "react-icons/bi";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import Permission from "../../../../../components/userPermission/userPermission";

const ExpenseTypesTabel = () => {
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const dispatch = useDispatch();

  const _ExpenseTypes = useSelector(
    (state) => state.expenseTypes.expensesTypes
  );
  const loading = useSelector(
    (state) => state.expenseTypes.expenseTypes.loading
  );
  const _SearchBody = useSelector((state) => state.expenseTypes.searchBody);

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(ExpenseTypes.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return <>{dayjs(params.row?.date).format("YYYY-MM-DD")}</>;
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="spending_type_edit">
              <IconButton
                onClick={() => dispatch(ExpenseTypes.getById(params.row.id))}
              >
                <BiEdit />
              </IconButton>
            </Permission>
            <Permission permission="spending_type_delete">
              <IconButton onClick={() => handleDeleteClick(params.row)}>
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        ExpenseTypes.getAll({
          search: search,
          skip: currentPage * pageSize,
          take: pageSize,
        })
      );
    }, 500);
    return () => clearTimeout(timeout);
  }, [search, currentPage, pageSize]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {/* <Grid item xs={2}>
            <TextField
              size="small"
              value={search.id}
              onChange={(e) => {
                setSearch({
                  ...search,
                  id: e.target.value
                });
              }}
              label="الاسم"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <Box sx={{ maxHeight: "70vh", width: "100%" }}>
              {_ExpenseTypes.data ? (
                <DataGrid
                  rows={_ExpenseTypes.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_ExpenseTypes.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  autoHeight
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExpenseTypesTabel;

import Typography from "@mui/material/Typography";
import {
  Button,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Slide,
  InputAdornment,
} from "@mui/material";
import { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { NumericFormat } from "react-number-format";
import {
  JClearance,
  setCashFromPosDialog,
} from "../../../../../app/slices/jomla/clearanceSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CashFromPosForm = () => {
  const { user_id } = useParams();
  const dispatch = useDispatch();

  const [reload, setReload] = useState(false);

  const _JCashFromPos = useSelector((state) => state.jClearance.cashFromPos);
  const searchBody = useSelector((state) => state.jClearance.searchBody);

  const { control, handleSubmit } = useForm({
    defaultValues: _JCashFromPos.form,
  });

  const onSubmit = (data) => {
    data.user_id = user_id;
    dispatch(JClearance.cashFromPos(data, searchBody)).then((x) => {
      setReload(!reload);
    });
  };

  const handleDialog = () => {
    dispatch(setCashFromPosDialog());
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: parseInt(values.value) || 0,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  });
  return (
    <Dialog
      open={_JCashFromPos.dialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth={"md"}
      dir="rtl"
      onClose={handleDialog}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent>
        <Typography
          mb={3}
          variant="h4"
          color={"purple"}
          gutterBottom
          component="div"
        >
          {"تسديد ديون النقطة"}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="المبلغ"
                    fullWidth
                    {...field}
                    sx={{ marginBottom: 1 }}
                    InputProps={{
                      inputComponent: NumericFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">IQD</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <Controller
                name="safe_id"
                control={control}
                render={({ field }) => (
                  <SelectJSafe
                    {...field}
                    required
                    onChange={(e, newValue) => {
                      setValue("safe_id", newValue ? newValue.id : null);
                    }}
                    value={jSafe.filter((x) => x.id === field.value)[0] ?? null}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <TextField
                    size="small"
                    label="ملاحظات"
                    fullWidth
                    multiline
                    rows={4}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                تسديد
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CashFromPosForm;

import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Investors,
  setBalanceDialog,
  setInvestorsTransactionsDialog,
  setSearchBody,
} from "../../../../../app/slices/investorsSlice";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../../../helpers/ConfirmationDialog";
import InitialInvestorsBalanceForm from "../../form/balance";
import { GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import { MdOutlineClear } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import Permission from "../../../../../components/userPermission/userPermission";

const InvestorsTable = () => {
  const dispatch = useDispatch();

  const _Investors = useSelector((state) => state.investors.investors);
  const loading = useSelector((state) => state.investors.investor.loading);
  const _SearchBody = useSelector((state) => state.investors.searchBody);
  const _InitialInvestorsBalance = useSelector(
    (state) => state.investors.balance.dialog
  );

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(Investors.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "index",
      headerName: "#",
      flex: 0.2,
    },
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "balance",
      headerName: "الرصيد",
      flex: 1,
      renderCell: (params) => parseInt(params.row.balance).toLocaleString(),
    },
    {
      field: "note",
      headerName: "الملاحظات",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.note}>
          <Typography variant="body" noWrap>
            {params.row.note}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) =>
        dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD "),
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="investors_edit">
              <Tooltip title="اضافة رصيد">
                <IconButton
                  variant="contained"
                  color="info"
                  onClick={() =>
                    dispatch(
                      setBalanceDialog({
                        id: params.row.id,
                        type: "deposit",
                      })
                    )
                  }
                >
                  <GiReceiveMoney />
                </IconButton>
              </Tooltip>
            </Permission>
            <Permission permission="investors_edit">
              <Tooltip title="سحب رصيد">
                <IconButton
                  variant="contained"
                  color="error"
                  onClick={() =>
                    dispatch(
                      setBalanceDialog({
                        id: params.row.id,
                        type: "withdraw",
                      })
                    )
                  }
                >
                  <GiPayMoney />
                </IconButton>
              </Tooltip>
            </Permission>
            <Tooltip title="المعاملات المالية">
              <IconButton
                variant="contained"
                onClick={() =>
                  dispatch(setInvestorsTransactionsDialog(params.row.id))
                }
              >
                <FaMoneyCheckAlt />
              </IconButton>
            </Tooltip>
            <Permission permission="investors_edit">
              <Tooltip title="تعديل">
                <IconButton
                  onClick={() => dispatch(Investors.getById(params.row.id))}
                >
                  <BiEdit />
                </IconButton>
              </Tooltip>
            </Permission>
            <Permission permission="investors_delete">
              <Tooltip title="حذف">
                <IconButton onClick={() => handleDeleteClick(params.row)}>
                  <BiTrash />
                </IconButton>
              </Tooltip>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(Investors.getAll({ search: search, skip: 0, take: 15 }));
      dispatch(setSearchBody({ search: search, skip: 0, take: 15 }));
    }, 500);
    return () => clearTimeout(timeout);
  }, [dispatch, search]);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          المجموع الكلي : {_Investors.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box>
          {_InitialInvestorsBalance && <InitialInvestorsBalanceForm />}
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={{}}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              size="small"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              label="بحث عن الاسم "
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack
              direction="row"
              spacing={0}
              sx={{ alignIssuedCard: "center" }}
            >
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch("");
                    setPage(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              <DataGrid
                rows={
                  _Investors.data?.map((x, index) => ({
                    ...x,
                    index: index + 1,
                  })) ?? []
                }
                columns={columns}
                pageSize={page}
                page={currentPage}
                rowCount={_Investors.total}
                loading={loading}
                rowHeight={50}
                pagination
                paginationMode="server"
                selectionModel={[]}
                onPageChange={(newPage) => {
                  dispatch(
                    Investors.getAll({
                      search: search,
                      skip: newPage * 15,
                      take: 15,
                    })
                  );
                  setCurrentPage(newPage);
                }}
                rowsPerPageOptions={[15, 25, 50, 100]}
                onPageSizeChange={(newPageSize) => {
                  dispatch(
                    Investors.getAll({
                      search: search,
                      skip: 0,
                      take: newPageSize,
                    })
                  );
                  setPage(newPageSize);
                }}
                components={{
                  LoadingOverlay: LinearProgress,
                  Footer: CustomFooter,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InvestorsTable;

import { Card, CardHeader, Typography, Button, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../../app/slices/jomla/transactionsSlice";
import JTransactionForm from "../../form";
import { GiMoneyStack, GiPayMoney, GiReceiveMoney } from "react-icons/gi";
import Permission from "../../../../../../components/userPermission/userPermission";

const JTransactionHeader = () => {
  const dispatch = useDispatch();
  const JTransaction = useSelector((state) => state.jTransaction);
  const _JSupplier = useSelector((state) => state.jSupplier.jSupplier.form);

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" spacing={2}>
            <Typography variant="h4" component="div">
              حركات الموردين المالية للجملة:
            </Typography>
            <Typography variant="h4">
              {"( "}المورد : {_JSupplier ? _JSupplier.name : ""}
            </Typography>
            <Typography variant="h4">
              الرصيد:{" "}
              {_JSupplier
                ? `IQD ${parseInt(_JSupplier.balance)?.toLocaleString()}`
                : ""}
              {" )"}
            </Typography>
          </Stack>
        }
        avatar={<GiMoneyStack size={20} />}
        action={
          <Stack direction='row' spacing={2}>
            <Permission permission="j_supplier_transaction_edit">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                startIcon={<GiPayMoney />}
                onClick={() => dispatch(setDialog("pay"))}
              >
                تسديد مبالغ
              </Button>
            </Permission>
            <Permission permission="j_supplier_transaction_edit">
              <Button
                variant="contained"
                color="primary"
                size="medium"
                startIcon={<GiReceiveMoney />}
                onClick={() => dispatch(setDialog("receive"))}
              >
                استلام مبالغ
              </Button>
            </Permission>
          </Stack>
        }
      />
      {JTransaction.jTransaction.dialog ? <JTransactionForm /> : null}
    </Card>
  );
};

export default JTransactionHeader;

import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/jomla/supplierSlice";
import JSupplierForm from "../../form";
import { FaBoxOpen } from "react-icons/fa";
import Permission from "../../../../../components/userPermission/userPermission";

const JSupplierHeader = () => {
  const dispatch = useDispatch();
  const JSupplier = useSelector((state) => state.jSupplier);

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الموردين
          </Typography>
        }
        avatar={<FaBoxOpen size={20} />}
        action={
          <Permission permission="j_supplier_edit">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة مورد
            </Button>
          </Permission>
        }
      />
      {JSupplier.jSupplier.dialog ? <JSupplierForm /> : null}
    </Card>
  );
};

export default JSupplierHeader;

import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import JSubCategoriesForm from "../../from";
import { TbSubtask } from "react-icons/tb";
import { setDialog } from "../../../../../../app/slices/jomla/subcategorySlice";
import Permission from "../../../../../../components/userPermission/userPermission";

const JSubCategoriesHeader = () => {
  const jSubCategories = useSelector((state) => state.JSubCategories);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            الاقسام الفرعية
          </Typography>
        }
        avatar={<TbSubtask size={20} />}
        action={
          <Permission permission="j_sub_category_edit">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة قسم فرعي
            </Button>
          </Permission>
        }
      />
      {jSubCategories.JSubCategory.dialog ? <JSubCategoriesForm /> : null}
    </Card>
  );
};

export default JSubCategoriesHeader;

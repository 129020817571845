import {
  Grid,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Tooltip,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash } from "react-icons/bi";
import { MdOutlineClear } from "react-icons/md";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ExternalRevenue, setSearchBody,
} from "../../../../app/slices/externalRevenueSlice";
import dayjs from "dayjs";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";
import DateRangepicker from "../../../../helpers/dateRangePicker";
import Permission from "../../../../components/userPermission/userPermission";

const ExternalRevenueTable = () => {

  const dispatch = useDispatch();

  const _ExternalRevenue = useSelector((state) => state.externalRevenue.externalRevenues);
  const loading = useSelector((state) => state.externalRevenue.externalRevenue.loading);
  const _SearchBody = useSelector((state) => state.externalRevenue.searchBody);

  const [search, setSearch] = useState({
    start_date: dayjs().format('YYYY-MM-01'),
    end_date: dayjs().format('YYYY-MM-DD'),
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState('');

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params)
  };

  const handleConfirmDelete = () => {
    dispatch(ExternalRevenue.deleteById(dialogData.id, _SearchBody))
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "id",
      headerName: "الرقم",
      flex: 1,
    },
    {
      field: "amount",
      headerName: "المبلغ",
      flex: 1,
      renderCell: (params) => parseInt(params.row.amount)?.toLocaleString()
    },
    {
      field: "note",
      headerName: "الملاحظة",
      flex: 2,
    },
    {
      field: "created_at",
      headerName: "التاريخ",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {dayjs(params.row.created_at).format("HH:mm / YYYY-MM-DD ")}
          </>
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="revenue_delete">
              <IconButton
                onClick={() => handleDeleteClick(params.row)}
              >
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(ExternalRevenue.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody]);

  useEffect(() => {
    dispatch(setSearchBody({
      // search: search.name,
      start_date: search.start_date ?? "",
      end_date: search.end_date ?? "",
      skip: currentPage * pageSize,
      take: pageSize,
    }))
  }, [currentPage, pageSize, search])

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.id}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format(
                      "YYYY-MM-DD"
                    ),
                  })
                }
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction='row' spacing={0} sx={{ alignItems: 'center' }}>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      pos_name: '',
                      pos_number: '',
                      status: null,
                      product_supplier_id: null,
                      start_date: dayjs().format("YYYY-MM-01"),
                      end_date: dayjs().format("YYYY-MM-DD"),
                    });
                    setPageSize(15)
                    setCurrentPage(0)
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {_ExternalRevenue.data ? (
                <DataGrid
                  rows={_ExternalRevenue.data}
                  columns={columns}
                  pageSize={pageSize}
                  page={currentPage}
                  rowCount={_ExternalRevenue.total}
                  loading={loading}
                  rowHeight={50}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) =>
                    setCurrentPage(newPage)
                  }
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }
                  }
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : <LinearProgress />}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ExternalRevenueTable;

import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { BiTrash, BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  JSubCategories,
  setSearchBody,
} from "../../../../../../app/slices/jomla/subcategorySlice";
import ConfirmationDialog from "../../../../../../helpers/ConfirmationDialog";
import { SelectJCategory } from "../../../../../../components/Selectors/jomla/SelectJCategory";
import Permission from "../../../../../../components/userPermission/userPermission";

const JSubCategoriesTable = () => {
  const dispatch = useDispatch();

  const JSubCategory = useSelector(
    (state) => state.JSubCategories.JSubCategories
  );
  const _SearchBody = useSelector((state) => state.JSubCategories.searchBody);
  const _JCategoryList = useSelector(
    (state) => state.JCategories.JCategoriesList.data
  );

  const [search, setSearch] = useState({
    search: "",
    category_id: null,
  });
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [openConfirm, setConfirmOpen] = useState(false);
  const [dialogData, setDialogData] = useState("");

  const handleDeleteClick = (params) => {
    setConfirmOpen(true);
    setDialogData(params);
  };

  const handleConfirmDelete = () => {
    dispatch(JSubCategories.deleteById(dialogData.id, _SearchBody));
    setConfirmOpen(false);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
  };

  const columns = [
    {
      field: "name",
      headerName: "الاسم",
      flex: 1,
    },
    {
      field: "logo",
      headerName: "الصورة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box component="img" src={params.row.logo} sx={{ height: 90 }} />
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Permission permission="j_sub_category_edit">
              <IconButton
                onClick={() => dispatch(JSubCategories.getById(params.row.id))}
              >
                <BiEdit />
              </IconButton>
            </Permission>
            <Permission permission="j_sub_category_delete">
              <IconButton onClick={() => handleDeleteClick(params.row)}>
                <BiTrash />
              </IconButton>
            </Permission>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JSubCategories.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        search: search.search,
        j_category_id: search.category_id ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      <CardContent>
        <Box>
          <ConfirmationDialog
            open={openConfirm}
            title="تاكيد"
            message="هل انت متاكد من حذف"
            name={dialogData.name}
            confirmText="حذف"
            cancelText="الغاء"
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancelDelete}
          />
        </Box>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={2}>
            <TextField
              size="small"
              onChange={(e) => {
                setSearch({
                  ...search,
                  search: e.target.value,
                });
              }}
              label="بحث عن الاسم "
              fullWidth
            />
          </Grid>
          <Grid item xs={2.5}>
            <SelectJCategory
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  category_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JCategoryList?.filter((x) => x.id === search.category_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ height: "70vh", width: "100%" }}>
              {JSubCategory.data ? (
                <DataGrid
                  rows={JSubCategory.data}
                  columns={columns}
                  pageSize={pageSize}
                  rowCount={JSubCategory.total}
                  loading={JSubCategory.loading}
                  rowHeight={100}
                  pagination
                  paginationMode="server"
                  selectionModel={[]}
                  onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                  }}
                  rowsPerPageOptions={[15, 25, 50, 100]}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize);
                  }}
                  components={{
                    LoadingOverlay: LinearProgress,
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JSubCategoriesTable;

import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { MdOutlinePayment } from "react-icons/md";
import { useSelector } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { setDialog } from "../../../../../app/slices/cardsSlice";
import CardForm from "../../form";
import Permission from "../../../../../components/userPermission/userPermission";
const CardsHeader = () => {
  const _Card = useSelector((state) => state.cards.card);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            البطاقات
          </Typography>
        }
        avatar={<MdOutlinePayment size={20} />}
        action={
          <Permission permission="card_edit">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة بطاقة جديدة
            </Button>
          </Permission>
        }
      />
      {_Card.dialog ? <CardForm /> : null}
    </Card>
  );
};

export default CardsHeader;

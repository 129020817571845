import { Card, CardHeader, Typography, Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { setDialog } from "../../../../../app/slices/simCardSlice";
import { FaSimCard } from "react-icons/fa";
import SimCardForm from "../../from";
import SendSmsForm from "../../from/SendSmsForm";
import Permission from "../../../../../components/userPermission/userPermission";

const SimCardHeader = () => {
  const _SimCard = useSelector((state) => state.simCard);
  const dispatch = useDispatch();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h4" component="div">
            سيم كارت
          </Typography>
        }
        avatar={<FaSimCard size={20} />}
        action={
          <Permission permission="sim_card_edit">
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<IoIosAddCircleOutline />}
              onClick={() => dispatch(setDialog())}
            >
              إضافة سيم كارت
            </Button>
          </Permission>
        }
      />
      {_SimCard.simCard.dialog ? <SimCardForm /> : null}
      {_SimCard.simCard.sendSmsDialog ? <SendSmsForm /> : null}
    </Card>
  );
};

export default SimCardHeader;

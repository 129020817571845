import {
  Grid,
  IconButton,
  TextField,
  LinearProgress,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
  Link,
  useMediaQuery,
  TablePagination,
  Autocomplete,
} from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MdOutlineClear,
  MdOutlineDeliveryDining,
  MdOutlinePointOfSale,
  MdPrint,
} from "react-icons/md";
import {
  JOrder,
  setLoading,
  setSearchBody,
  setAssignDriverDialog,
  setChangePosDialog,
} from "../../../../../app/slices/jomla/orderSlice";
import dayjs from "dayjs";
import DateRangepicker from "../../../../../helpers/dateRangePicker";
import { ChangeStatusByChip } from "./ChangeStatusByChip";
import { SelectJItem } from "../../../../../components/Selectors/jomla/SelectJItem";
import { JItems } from "../../../../../app/slices/jomla/itemsSlice";
import { BiDetail } from "react-icons/bi";
import { SelectJSubCategory } from "../../../../../components/Selectors/jomla/SelectJSubCategory";
import { SelectJOrderStatus } from "../../../../../components/Selectors/jomla/SelectJOrderStatus";
import { PointOfSales } from "../../../../../app/slices/posSlice";
import PosForm from "../../../../POS/form";
import { SelectPos } from "../../../../../components/Selectors/SelectPos";
import { SelectDriver } from "../../../../../components/Selectors/jomla/SelectDriver";
import { useTheme } from "@emotion/react";
import { SelectJRep } from "../../../../../components/Selectors/SelectJRep";
import { SelectProvince } from "../../../../../components/Selectors/SelectProvince";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import Permission from "../../../../../components/userPermission/userPermission";

const JOrderTable = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const _provinces = useSelector((state) => state.provinces.provinces.data);
  const _JOrder = useSelector((state) => state.jOrder.jOrders);
  const _SearchBody = useSelector((state) => state.jOrder.searchBody);
  const _JItems = useSelector((state) => state.JItems.JItemsList.data);
  const _JSubCategory = useSelector(
    (state) => state.JSubCategories.JSubCategoriesList.data
  );
  const _JOrderStatus = useSelector(
    (state) => state.jOrder.jOrderStatuses.data
  );
  const _PointsOfSales = useSelector((state) => state.pointOfsales);
  const _PointOfSales = useSelector(
    (state) => state.pointOfsales.posesList.data
  );
  const user = useSelector((state) => state.user.jRepList.data);
  const drivers = useSelector((state) => state.user.driversList.data);
  const _Role = useSelector((state) => state.user.userInfo.user_roles[0]);

  const has_debt = [
    {
      key: "ديون فقط",
      value: true,
    },
    {
      key: "بدون ديون فقط",
      value: false,
    },
  ];

  useEffect(() => {
    dispatch(JItems.getAllList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [search, setSearch] = useState(_SearchBody);
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);

  function CustomFooter() {
    return (
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography color="primary" sx={{ marginX: 2 }}>
          القيمة الكلية : {_JOrder.total_amount.toLocaleString()} (IQD)
        </Typography>
        <GridPagination />
      </Stack>
    );
  }

  const changeOrderStatus = (id, status) => {
    let params = {
      j_order_status_id: status?.id,
    };

    dispatch(JOrder.updateStatus(id, params, _SearchBody, "getAll"));
  };

  const columns = [
    {
      field: "number",
      headerName: "رقم الطلب",
      flex: 0.5,
    },
    {
      field: "user_name",
      headerName: "النقطة",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack>
            <Typography>
              {_Role === "wholesale" ? (
                params.row?.user_name
              ) : (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    dispatch(setLoading("jOrders"));
                    dispatch(PointOfSales.getByUserId(params.row.user_id)).then(
                      () => dispatch(setLoading("jOrders"))
                    );
                  }}
                >
                  {params.row?.user_name}
                </Link>
              )}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "items_count",
      headerName: "عدد المنتجات",
      flex: 0.6,
    },
    {
      field: "net_amount",
      headerName: "المبلغ النهائي (IQD)",
      flex: 1,
      renderCell: (params) => params.row.amount?.toLocaleString(),
    },
    {
      field: "discount",
      headerName: "الخصم (IQD)",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body1">
          {params.row.discount?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "admin_discount",
      headerName: "خصم الشركة (IQD)",
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body1">
          {params.row.admin_discount?.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: "total_paid",
      headerName: "المدفوع (IQD)",
      flex: 1,
      renderCell: (params) => params.row.total_paid?.toLocaleString(),
    },
    {
      field: "remaining",
      headerName: "المتبقي (IQD)",
      flex: 1,
      renderCell: (params) => params.row.remaining?.toLocaleString(),
    },

    // {
    //   field: "main_j_balance",
    //   headerName: "الخزنة (IQD)",
    //   flex: 1,
    //   renderCell: (params) =>
    //     <Stack alignItems='start'>
    //       <Typography variant="body" textAlign='center'>قبل: {params.row.main_j_balance?.toLocaleString()}</Typography>
    //       <Typography variant="body" textAlign='center'>بعد: {params.row.new_main_j_balance?.toLocaleString()}</Typography>
    //     </Stack>,
    // },
    {
      field: "created_at",
      headerName: "تاريخ الانشاء",
      flex: 1,
      renderCell: (params) => (
        <Stack spacing={0}>
          <Typography variant="body1">
            {dayjs(params.row.created_at).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="body1">
            {dayjs(params.row.created_at).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "j_order_status_id",
      headerName: "الحالة",
      flex: 1.2,
      renderCell: (params) => {
        return (
          <ChangeStatusByChip
            id={params.row.j_order_status_id}
            status={params.row.j_order_status_name}
            onChangeStatus={(item) => {
              changeOrderStatus(params.row.id, item);
            }}
          />
        );
      },
    },
    {
      field: "operations",
      headerName: "العمليات",
      flex: 1.2,
      renderCell: (params) => (
        <Stack
          direction="row"
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <Permission permission="j_order_view">
            <Tooltip title="تفاصيل الطلب" followCursor>
              <IconButton
                // onClick={() => {
                //   navigate(`/app/wholesale/order/${params.row.id}`);
                // }}
                onClick={() => {
                  window.open(`/app/wholesale/order/${params.row.id}`, "_blank");
                }}
              >
                <BiDetail />
              </IconButton>
            </Tooltip>
          </Permission>
          <Tooltip title="طباعة فاتورة بيع" followCursor>
            <IconButton
              onClick={() => {
                dispatch(JOrder.getByIdForPrint(params.row.id));
              }}
            >
              <MdPrint />
            </IconButton>
          </Tooltip>
          <Permission permission="assign_j_order_driver_edit">
            <Tooltip
              title={
                <Typography
                  variant={params.row.driver_name ? "subtitle1" : "body"}
                >
                  {params.row.driver_name
                    ? "السائق: " + params.row.driver_name
                    : "تعيين سائق"}
                </Typography>
              }
              followCursor
            >
              <IconButton
                color={params.row.driver_name ? "error" : "default"}
                onClick={() => {
                  dispatch(
                    setAssignDriverDialog({
                      order_id: params.row.id,
                      driver_id: params.row.driver_id ?? null,
                    })
                  );
                }}
              >
                <MdOutlineDeliveryDining />
              </IconButton>
            </Tooltip>
          </Permission>
          {params.row.j_order_status_id === 1 ||
            params.row.j_order_status_id === 2 ||
            params.row.j_order_status_id === 3 ||
            params.row.j_order_status_id === 4 ? (
            <Tooltip
              title={
                <Typography
                  variant={params.row.driver_name ? "subtitle1" : "body"}
                >
                  {"تغيير نقطة البيع"}
                </Typography>
              }
              followCursor
            >
              <IconButton
                color={params.row.driver_name ? "error" : "default"}
                onClick={() => {
                  dispatch(
                    setChangePosDialog({
                      order_id: params.row.id,
                      user_id: params.row.user_id,
                      note: "",
                    })
                  );
                }}
              >
                <MdOutlinePointOfSale color="green" />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(JOrder.getAll(_SearchBody));
    }, 500);
    return () => clearTimeout(timeout);
  }, [_SearchBody, dispatch]);

  useEffect(() => {
    dispatch(
      setSearchBody({
        number: search.number,
        j_sub_category_id: search.j_sub_category_id ?? "",
        pos_id: search.pos_id ?? "",
        j_rep_id: search.j_rep_id ?? "",
        j_order_status_ids: search.j_order_status_ids
          ? JSON.stringify(search.j_order_status_ids)
          : "",
        j_item_id: search.j_item_id ?? "",
        start_date: search.start_date ?? "",
        end_date: search.end_date ?? "",
        driver_id: search.driver_id ?? "",
        province_id: search.province_id ?? "",
        has_debt: search.has_debt ?? "",
        pos_number: search.pos_number ?? "",
        skip: currentPage * pageSize,
        take: pageSize,
      })
    );
  }, [currentPage, dispatch, pageSize, search]);

  return (
    <Card>
      {_PointsOfSales.pos.dialog ? (
        <PosForm from={"JOrders"} jOrderSearchBody={_SearchBody} />
      ) : null}
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              type="number"
              size="small"
              value={search.number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  number: e.target.value,
                });
              }}
              label="رقم الطلب"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectJOrderStatus
              multiple
              onChange={(event, value) => {
                var ids = value.map((x) => x.id);
                setSearch({
                  ...search,
                  j_order_status_ids: value ? ids : null,
                });
              }}
              value={
                _JOrderStatus?.filter((x) =>
                  search.j_order_status_ids?.includes(x.id)
                ) ?? []
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectJSubCategory
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_sub_category_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JSubCategory.filter(
                  (x) => x.id === search.j_sub_category_id
                )[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectJItem
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_item_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _JItems.filter((x) => x.id === search.j_item_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              type="number"
              size="small"
              value={search.pos_number}
              onChange={(e) => {
                setSearch({
                  ...search,
                  pos_number: e.target.value,
                });
              }}
              label="رقم النقطة"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectPos
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  pos_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _PointOfSales?.filter((x) => x.id === search.pos_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectJRep
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  j_rep_id: newValue ? newValue.id : null,
                });
              }}
              value={
                user.filter((user) => user.id === search.j_rep_id)[0] ?? null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectProvince
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  province_id: newValue ? newValue.id : null,
                });
              }}
              value={
                _provinces?.filter((x) => x.id === search.province_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectDriver
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  driver_id: newValue ? newValue.id : null,
                });
              }}
              value={
                drivers.filter((user) => user.id === search.driver_id)[0] ??
                null
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              options={has_debt}
              getOptionLabel={(option) => {
                return option.key;
              }}
              onChange={(e, newValue) => {
                setSearch({
                  ...search,
                  has_debt: newValue ? newValue.value : null,
                });
              }}
              value={
                has_debt.filter((x) => x.value === search.has_debt)[0] ?? null
              }
              renderInput={(params) => (
                <TextField {...params} label="الديون" size="small" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DateRangepicker
              startDate={search.start_date}
              endDate={search.end_date}
              handleOnChange={(searchDate) => {
                const updatedFilter = {
                  from: dayjs(searchDate[0].startDate).format("YYYY-MM-DD"),
                  to: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                };
                if (
                  updatedFilter.from !== searchDate.startDate ||
                  updatedFilter.to !== searchDate.endDate
                ) {
                  setSearch({
                    ...search,
                    start_date: dayjs(searchDate[0].startDate).format(
                      "YYYY-MM-DD"
                    ),
                    end_date: dayjs(searchDate[0].endDate).format("YYYY-MM-DD"),
                  });
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={0.5}>
            <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
              <Tooltip title="تصدير ملف اكسل">
                <IconButton
                  onClick={() => {
                    dispatch(JOrder.exportExcel(_SearchBody));
                  }}
                >
                  <BsFillFileEarmarkExcelFill />
                </IconButton>
              </Tooltip>
              <Tooltip title="الغاء الفلتر">
                <IconButton
                  onClick={() => {
                    setSearch({
                      number: "",
                      j_sub_category_id: null,
                      j_item_id: null,
                      start_date: null,
                      end_date: null,
                      driver_id: null,
                      j_order_status_ids: null,
                      pos_id: null,
                      j_rep_id: null,
                      province_id: null,
                      has_debt: null,
                      pos_number: "",
                    });
                    setPageSize(15);
                    setCurrentPage(0);
                  }}
                >
                  <MdOutlineClear />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
          {smallScreen ? (
            _JOrder?.data?.length > 0 ? (
              <Grid item xs={12}>
                {_JOrder.loading ? <LinearProgress /> : null}
                {_JOrder.data.map((order, index) => (
                  <Card sx={{ marginBottom: 0.5 }} key={index}>
                    <CardContent>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                          >
                            <Typography variant="h4" color="primary">
                              #{order.number}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            النقطة:
                            <Link
                              component="button"
                              variant="body2"
                              onClick={() => {
                                dispatch(setLoading("jOrders"));
                                dispatch(
                                  PointOfSales.getByUserId(order.user_id)
                                ).then(() => dispatch(setLoading("jOrders")));
                              }}
                            >
                              {order.user_name}
                            </Link>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            عدد المنتجات: <strong>{order.items_count}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            المبلغ النهائي (IQD):{" "}
                            <strong>{order.amount?.toLocaleString()}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            الخصم (IQD):{" "}
                            <strong>{order.discount?.toLocaleString()}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            خصم الشركة (IQD):{" "}
                            <strong>
                              {order.admin_discount?.toLocaleString()}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            المدفوع (IQD):{" "}
                            <strong>
                              {order.total_paid?.toLocaleString()}
                            </strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            المتبقي (IQD):{" "}
                            <strong>{order.remaining?.toLocaleString()}</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Stack spacing={0}>
                            <Typography variant="body1">
                              {dayjs(order.created_at).format("YYYY-MM-DD")}
                            </Typography>
                            <Typography variant="body1">
                              {dayjs(order.created_at).format("hh:mm a")}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <ChangeStatusByChip
                            id={order.j_order_status_ids}
                            status={order.j_order_status_name}
                            onChangeStatus={(item) => {
                              changeOrderStatus(order.id, item);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            spacing={0.5}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Tooltip title="تفاصيل الطلب" followCursor>
                              <IconButton
                                onClick={() => {
                                  window.open(
                                    `/app/wholesale/order/${order.id}`,
                                    "_blank"
                                  );
                                }}
                              >
                                <BiDetail />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="طباعة فاتورة بيع" followCursor>
                              <IconButton
                                onClick={() => {
                                  dispatch(JOrder.getByIdForPrint(order.id));
                                }}
                              >
                                <MdPrint />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={
                                <Typography
                                  variant={
                                    order.driver_name ? "subtitle1" : "body"
                                  }
                                >
                                  {order.driver_name
                                    ? "السائق: " + order.driver_name
                                    : "تعيين سائق"}
                                </Typography>
                              }
                              followCursor
                            >
                              <IconButton
                                color={order.driver_name ? "error" : "default"}
                                onClick={() => {
                                  dispatch(
                                    setAssignDriverDialog({
                                      order_id: order.id,
                                      driver_id: order.driver_id ?? null,
                                    })
                                  );
                                }}
                              >
                                <MdOutlineDeliveryDining />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
                <Card sx={{ mt: 2, width: "100%" }}>
                  <TablePagination
                    component="div"
                    count={_JOrder.total}
                    rowsPerPage={pageSize}
                    page={currentPage}
                    onPageChange={(e, newPage) => setCurrentPage(newPage)}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onRowsPerPageChange={(event) => {
                      const newRowsPerPage = parseInt(event.target.value, 10);
                      setPageSize(newRowsPerPage);
                    }}
                  />
                </Card>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Card>
                  <Typography m={2} variant="h5" color="primary" align="center">
                    لا توجد بيانات
                  </Typography>
                </Card>
              </Grid>
            )
          ) : (
            <Grid item xs={12}>
              <Box sx={{ height: "66vh", width: "100%" }}>
                {_JOrder.data ? (
                  <DataGrid
                    rows={_JOrder.data.map((x, i) => {
                      return {
                        ...x,
                        index: i + 1 + pageSize * currentPage,
                      };
                    })}
                    columns={columns}
                    pageSize={pageSize}
                    page={currentPage}
                    rowCount={_JOrder.total}
                    loading={_JOrder.loading}
                    rowHeight={60}
                    pagination
                    paginationMode="server"
                    selectionModel={[]}
                    onPageChange={(newPage) => {
                      setCurrentPage(newPage);
                    }}
                    rowsPerPageOptions={[15, 25, 50, 100]}
                    onPageSizeChange={(newPageSize) => {
                      setPageSize(newPageSize);
                    }}
                    components={{
                      LoadingOverlay: LinearProgress,
                      Footer: CustomFooter,
                    }}
                  />
                ) : (
                  <LinearProgress />
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default JOrderTable;
